import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import mobilitiModeOff from "../../assets/img/mobiliti_mode_off.svg";
import mobilitiModeOn from "../../assets/img/mobiliti_mode_on.svg";
import { updateFilters } from "../../reducers/filtersReducer";
import "./MobilitiModeToggle.scss";

function MobilitiModeToggle() {
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const { filterTypes } = useSelector((state) => state.filters);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(filterTypes.includes("mobiliti"));
  }, [filterTypes]);

  const toggle = () => {
    setToggled((toggled) => !toggled);

    let changedFilterTypes = [...filterTypes];
    if (!toggled) {
      changedFilterTypes.push("mobiliti");
    } else {
      changedFilterTypes = changedFilterTypes.filter((item) => item !== "mobiliti");
    }
    dispatch(
      updateFilters({
        filterTypes: changedFilterTypes,
      })
    );
  };

  return (
    <>
      <img
        alt="mobiliti mode toggle icon"
        src={toggled ? mobilitiModeOn : mobilitiModeOff}
        style={{
          background: themeContext.name === "light" ? "#FFFFFF" : "#1C1C1E",
        }}
        className="mobilitiModeIcon"
        onClick={toggle}
      />
    </>
  );
}

export default MobilitiModeToggle;
