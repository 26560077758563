import PropTypes from "prop-types";
import { lightning } from "../../../../assets/img";
import { ChargerType } from "../../../shared/Constants";
import "./ConnectorTitleWithPower.scss";

function ConnectorTitleWithPower({ plugType, power }) {
  return (
    <div className="connectorTitleWithPower">
      <div>{ChargerType[plugType.toLowerCase()]?.label ?? plugType}</div>
      <div>
        <img src={lightning.default} alt="lightning" />
        {power / 1000} kW
      </div>
    </div>
  );
}

ConnectorTitleWithPower.propTypes = {
  plugType: PropTypes.string.isRequired,
  power: PropTypes.number.isRequired,
};

export default ConnectorTitleWithPower;
