import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import huLocale from "i18n-iso-countries/langs/hu.json";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

countries.registerLocale(huLocale);
countries.registerLocale(enLocale);

const useCountries = () => {
  const { i18n } = useTranslation();

  const getCountryName = useCallback(
    (countryCode) => {
      return countries.getName(countryCode, i18n.language.toLowerCase());
    },
    [i18n.language]
  );

  return {
    getCountryName,
  };
};

export default useCountries;
