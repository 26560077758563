import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setMapType } from "../../../reducers/themeReducer";
import MobilitiButton from "../../shared/elements/MobilitiButton";
import "./MapTypeSwitcher.scss";

function MapTypeSwitcher() {
  const { mapType } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeMapType = (value) => {
    dispatch(setMapType(value));
  };

  return (
    <div className="mapTypeSwitcherWrapper">
      <h2>{t("filters.mapType.title")}</h2>
      <MobilitiButton
        active={mapType === "roadmap"}
        label={t("filters.mapType.roadmap")}
        onClick={() => changeMapType("roadmap")}
      />
      <MobilitiButton
        active={mapType === "satellite"}
        label={t("filters.mapType.satellite")}
        onClick={() => changeMapType("satellite")}
      />
      <MobilitiButton
        active={mapType === "hybrid"}
        label={t("filters.mapType.hybrid")}
        onClick={() => changeMapType("hybrid")}
      />
      <MobilitiButton
        active={mapType === "terrain"}
        label={t("filters.mapType.terrain")}
        onClick={() => changeMapType("terrain")}
      />
    </div>
  );
}

MapTypeSwitcher.propTypes = {};

export default MapTypeSwitcher;
