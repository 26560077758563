import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../reducers/themeReducer";

export function useTimedDarkMode() {
  const { timedDarkMode, name: currentTheme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const toggleDarkMode = useCallback(
    (minutesPassedToday) => {
      if (timedDarkMode.enabled) {
        let newTheme = "light";
        const darkModeStartInMinutes = timedDarkMode.startHours * 60 + timedDarkMode.startMinutes;
        const darkModeEndInMinutes = timedDarkMode.endHours * 60 + timedDarkMode.endMinutes;

        if (
          timedDarkMode.startHours < timedDarkMode.endHours &&
          minutesPassedToday >= darkModeStartInMinutes &&
          minutesPassedToday <= darkModeEndInMinutes
        ) {
          newTheme = "dark";
        } else if (
          timedDarkMode.startHours > timedDarkMode.endHours &&
          ((minutesPassedToday >= darkModeStartInMinutes && minutesPassedToday < 24 * 60) ||
            minutesPassedToday <= darkModeEndInMinutes)
        ) {
          newTheme = "dark";
        } else if (
          timedDarkMode.startHours === timedDarkMode.endHours &&
          timedDarkMode.startMinutes < timedDarkMode.endMinutes &&
          minutesPassedToday >= darkModeStartInMinutes &&
          minutesPassedToday <= darkModeEndInMinutes
        ) {
          newTheme = "dark";
        } else if (
          timedDarkMode.startHours === timedDarkMode.endHours &&
          timedDarkMode.startMinutes > timedDarkMode.endMinutes &&
          ((minutesPassedToday >= darkModeStartInMinutes && minutesPassedToday < 24 * 60) ||
            minutesPassedToday <= darkModeEndInMinutes)
        ) {
          newTheme = "dark";
        }

        if (currentTheme !== newTheme) {
          dispatch(setTheme(newTheme));
        }
      }
    },
    [
      currentTheme,
      dispatch,
      timedDarkMode.enabled,
      timedDarkMode.endHours,
      timedDarkMode.endMinutes,
      timedDarkMode.startHours,
      timedDarkMode.startMinutes,
    ]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const minutesPassedToday = now.getHours() * 60 + now.getMinutes();
      toggleDarkMode(minutesPassedToday);
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [timedDarkMode, toggleDarkMode]);
}
