import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ChargerType } from "../../../shared/Constants";
import "./ConnectorImage.scss";

function ConnectorImage({ label, plugType, status }) {
  const themeContext = useContext(ThemeContext);

  return (
    <div className={`connectorImage ${status ? "connectorImage-" + status.toLowerCase() : ""} ${themeContext.name}`}>
      <div>{label}</div>
      {ChargerType[plugType.toLowerCase()] && (
        <img
          src={
            status
              ? ChargerType[plugType.toLowerCase()]["dark"].smallIcon.default
              : ChargerType[plugType.toLowerCase()][themeContext.name].smallIcon.default
          }
          alt="plug type"
        />
      )}
    </div>
  );
}

ConnectorImage.propTypes = {
  plugType: PropTypes.string.isRequired,
  label: PropTypes.string,
  status: PropTypes.string,
};

export default ConnectorImage;
