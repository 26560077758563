import PropTypes from "prop-types";
import "./Contact.scss";

function Contact({ contact, type }) {
  return (
    <div>
      {type === "website" ? (
        <a href={contact}>{contact}</a>
      ) : type === "email" ? (
        <a href={`mailto:${contact}`}>{contact}</a>
      ) : type === "phone" ? (
        <a href={`tel:${contact}`}>{contact}</a>
      ) : (
        <div>{contact}</div>
      )}
    </div>
  );
}

Contact.propTypes = {
  contact: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Contact;
