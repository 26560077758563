import PropTypes from "prop-types";
import { useContext } from "react";
import Switch from "react-switch";
import { ThemeContext } from "styled-components";
import "./MobilitiToggle.scss";

function MobilitiToggle(props) {
  const themeContext = useContext(ThemeContext);

  const onChange = () => {
    if (props.onChange) {
      props.onChange(!props.value);
    }
  };

  return (
    <span className={`mobilitiToggleWrapper ${props.value ? "toggle-on" : "toggle-off"}`}>
      <Switch
        offColor={themeContext.name === "light" ? "#D1D9E4" : "#5A5A5E"}
        onColor={themeContext.name === "light" ? "#408E8D" : "#52D0CF"}
        offHandleColor="#ffffff"
        onHandleColor="#ffffff"
        uncheckedIcon={false}
        handleDiameter={23}
        checkedIcon={false}
        className="clusteringSwitch"
        onChange={onChange}
        checked={props.value}
      />
    </span>
  );
}

MobilitiToggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool.isRequired,
};

export default MobilitiToggle;
