import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import { freeOnly, freeParking, manufacturer, openingHours, operator, publicCharger } from "../../../assets/img";
import "./LocationDetailItems.scss";

function LocationDetailItems({ location }) {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <div className={`location-detail-items ${themeContext.name}`}>
        <div className="location-detail-item">
          <div>
            <img src={freeParking.default} alt="parking" />
            <div className="text-left">{t("location.parking")}</div>
          </div>
          <div>
            <div className="text-right">
              {location.parking === 0 ? t("location.free") : t("location.paid")}
              {` (${location?.parkingSpaces ?? 0} ${t("location.parkingSpaces")})`}
            </div>
          </div>
        </div>
        <div className="location-detail-item">
          <div>
            <img src={publicCharger.default} alt="public charger" />
            <div className="text-left">{t("location.access")}</div>
          </div>
          <div>
            <div className="text-right">
              {location.usageType.toLowerCase() === "private" ? t("location.private") : t("location.public")}
            </div>
          </div>
        </div>
        <div className="location-detail-item">
          <div>
            <img src={freeOnly.default} alt="public charger" />
            <div className="text-left">{t("location.costs")}</div>
          </div>
          <div>
            <div className="text-right">{location.usageCost === 0 ? t("location.free") : t("location.paid")}</div>
          </div>
        </div>
        <div className="location-detail-item">
          <div>
            <img src={openingHours.default} alt="opening hours" />
            <div className="text-left">{t("location.openingHours")}</div>
          </div>
          {location.openHours && (
            <div>
              <div className="text-right">
                {location.openHours.ALLDAYS
                  ? location.openHours.ALLDAYS
                  : `${location.openHours.WEEKDAYS ? t("location.weekdays") + location.openHours.WEEKDAYS : ""}
                    ${location.openHours.SATURDAY ? t("location.saturday") + location.openHours.SATURDAY : ""}
                    ${location.openHours.SUNDAY ? t("location.sunday") + location.openHours.SUNDAY : ""}`}
              </div>
            </div>
          )}
        </div>
        {location.operator && (
          <div className="location-detail-item">
            <div>
              <img src={operator.default} alt="operator" />
              <div className="text-left">{t("location.operator")}</div>
            </div>
            <div>
              <div className="text-right">{location.operator.name}</div>
            </div>
          </div>
        )}
        {location.manufacturer && (
          <div className="location-detail-item">
            <div>
              <img src={manufacturer.default} alt="operator" />
              <div className="text-left">{t("location.manufacturer")}</div>
            </div>
            <div>
              <div className="text-right">{location.manufacturer}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

LocationDetailItems.propTypes = {
  location: PropTypes.object,
};

export default LocationDetailItems;
