import styled from "styled-components";
import "./MobilitiAutoComplete.scss";

const MobilitiAutoCompleteInputField = styled.input`
    background-color: ${(props) => (props.themeContext.name === "light" ? "rgb(240, 241, 242)" : "#1C1C1E")};
    color: ${(props) => (props.themeContext.name === "dark" ? "#FFFFFF" : "#404044")};

    &::placeholder {
        color: ${(props) => (props.themeContext.name === "dark" ? "#FFFFFF" : "#404044")}
    }
    
    @media screen and (max-width: 600px) {
        box-sizing: border-box;
        border: 1px solid transparent;
        width: 94vw;
        height: 40px;
        padding: 0 50px 0 12px;
        background-size: 20px 20px;
        border-radius: 3px;
        font-size: 14px;
        outline: none;
        position: absolute;
        left: 0px;
        margin-top: 0px;
        margin-left: 10px;
    }
    
    @media screen and (min-width: 601px) {
        box-sizing: border-box;
        border: 1px solid transparent;
        width: 350px;
        height: 40px;
        padding: 0 50px 0 12px;
        background-size: 20px 20px;
        border-radius: 3px;
        font-size: 14px;
        outline: none;
        position: absolute;
        left: 0px;
        margin-top: 0px;
        margin-left: 10px;
    }
}`;

export default MobilitiAutoCompleteInputField;
