import { useContext } from "react";
import { ThemeContext } from "styled-components";
import Disclaimer from "./Disclaimer";
import "./Footer.scss";
import FooterTitles from "./FooterTitles";
import StoreBadges from "./StoreBadges";

function Footer() {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      className="footer"
      style={{
        boxShadow: themeContext.name === "light" ? "0 0 20px rgba(35, 35, 35, 0.2)" : "0 0 20px rgb(0 0 0)",
        backgroundColor: themeContext.name === "light" ? "white" : "rgb(40, 40, 40)",
      }}
    >
      <FooterTitles />
      <Disclaimer />
      <div
        style={{
          float: "right",
        }}
      >
        <StoreBadges />
      </div>
    </div>
  );
}

export default Footer;
