import { child, onValue, ref } from "firebase/database";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import snowToggleOff from "../../assets/img/snow/snow_toggle_off.svg";
import snowToggleOffDark from "../../assets/img/snow/snow_toggle_off_dark.svg";
import snowToggleOn from "../../assets/img/snow/snow_toggle_on.svg";
import snowToggleOnDark from "../../assets/img/snow/snow_toggle_on_dark.svg";
import firebaseDatabase from "../../config/fbConfig";
import { toggleIsSnowing, toggleWinter } from "../../reducers/themeReducer";
import "./SnowToggle.scss";

function SnowToggle() {
  const themeContext = useContext(ThemeContext);
  const { isSnowing, winter } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const setIsSnowing = () => {
    dispatch(toggleIsSnowing());
  };

  const setWinter = useCallback(
    (value) => {
      dispatch(toggleWinter(value));
    },
    [dispatch]
  );

  useEffect(() => {
    const seasonalEvents = child(ref(firebaseDatabase, "/VarData"), "SeasonalEvent");
    onValue(seasonalEvents, (snapshot) => {
      const result = snapshot.val();
      const isWinterEnabled = result.events ? result.events.winter : false;
      setWinter(isWinterEnabled);
    });
  }, [setWinter]);

  const icon =
    themeContext.name === "light"
      ? isSnowing
        ? snowToggleOn
        : snowToggleOff
      : isSnowing
      ? snowToggleOnDark
      : snowToggleOffDark;

  return <>{winter && <img alt="snow toggle icon" src={icon} className="snowToggleIcon" onClick={setIsSnowing} />}</>;
}

export default SnowToggle;
