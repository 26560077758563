import PropTypes from "prop-types";
import { Component } from "react";

class EscapeOutside extends Component {
  componentDidMount = () => {
    document.addEventListener("keydown", this.onEscape);
    document.addEventListener("click", this.onClick, true);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.onEscape);
    document.removeEventListener("click", this.onClick, true);
  };

  onEscape = (e) => {
    if (e.keyCode === 27 && !e.target.className.includes("rrt-")) {
      this.props.onEscapeOutside();
    }
  };

  onClick = (e) => {
    const exceptions = [
      "pac-target-input",
      "rrt-",
      "shadow",
      "share-location-link",
      "share-location-popup",
      "mobiliti-popup-label",
    ];
    if (
      this.ref &&
      !this.ref.contains(e.target) &&
      !exceptions.some((substring) => e.target.className.includes(substring)) &&
      e.target.nodeName !== "TEXTAREA"
    ) {
      this.props.onEscapeOutside(e);
    }
  };

  getRef = (ref) => {
    this.ref = ref;
  };

  render = () => {
    const props = Object.assign({}, this.props);
    const { children } = props;

    delete props.onEscapeOutside;
    delete props.children;
    delete props.mouseEvent;
    delete props.touchEvent;

    return (
      <div ref={this.getRef} {...props}>
        {children}
      </div>
    );
  };
}

export default EscapeOutside;

EscapeOutside.propTypes = {
  children: PropTypes.element,
  onEscapeOutside: PropTypes.func.isRequired,
  mouseEvent: PropTypes.any,
  touchEvent: PropTypes.any,
};
