import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import lightning_yellow from "../../../assets/img/lightning_yellow.svg";
import { updateFilters } from "../../../reducers/filtersReducer";
import "./MinChargingSpeed.scss";

function MinChargingSpeed() {
  const themeContext = useContext(ThemeContext);
  const { minChargingSpeed } = useSelector((state) => state.filters);
  const [sliderValue, setSliderValue] = useState(0);
  const dispatch = useDispatch();
  const minChargingSpeeds = useMemo(() => [0, 10, 20, 40, 50, 100, 300], []);
  const { t } = useTranslation();

  useEffect(() => {
    setSliderValue(minChargingSpeeds.indexOf(minChargingSpeed));
  }, [minChargingSpeed, minChargingSpeeds]);

  const handleSliderValueChange = useCallback(
    (minChargingSpeedIndex) => {
      setTimeout(() => {
        dispatch(
          updateFilters({
            minChargingSpeed: minChargingSpeeds[minChargingSpeedIndex],
          })
        );
      }, 100);
      setSliderValue(minChargingSpeedIndex);
    },
    [dispatch, minChargingSpeeds]
  );

  return useMemo(
    () => (
      <>
        <h2>{t("filters.minChargingSpeed")}</h2>
        <div className={`sliderWrapper ${themeContext.name}`}>
          <Slider
            onChange={handleSliderValueChange}
            value={sliderValue}
            handleStyle={{
              backgroundImage: `url(${lightning_yellow})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "20px 20px",
              backgroundPosition: "6px",
            }}
            min={0}
            max={6}
            defaultValue={0}
            marks={minChargingSpeeds}
          />
        </div>
      </>
    ),
    [handleSliderValueChange, minChargingSpeeds, sliderValue, t, themeContext.name]
  );
}

export default MinChargingSpeed;
