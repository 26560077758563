import { child, onValue, ref } from "firebase/database";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { ThemeContext } from "styled-components";
import { dislike, dislikeDark, like, likeDark } from "../../../assets/img";
import firebaseDatabase from "../../../config/fbConfig";
import { downloadMobilitiAppDialogOptions } from "../../shared/Constants";
import "./Ratings.scss";

function Likes({ location }) {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const [ratings, setRatings] = useState({});

  useEffect(() => {
    if (location) {
      const locationRatings = child(child(ref(firebaseDatabase, "/Ratings"), "Locations"), location.id);
      onValue(locationRatings, (snapshot) => {
        setRatings(snapshot.val());
      });
    }
  }, [location]);

  return (
    <>
      {ratings && (
        <div className={`likes ${themeContext.name}`}>
          <div
            className="like-indicator"
            onClick={() =>
              toastr.confirm(null, downloadMobilitiAppDialogOptions(themeContext.name, t("popup.downloadApp")))
            }
          >
            <img src={themeContext.name === "light" ? like.default : likeDark.default} alt="likes" />
            <div className="like-counter">{ratings.likes ? ratings.likes : 0}</div>
          </div>
          <div
            className="dislike-indicator"
            onClick={() =>
              toastr.confirm(null, downloadMobilitiAppDialogOptions(themeContext.name, t("popup.downloadApp")))
            }
          >
            <div className="like-counter">{ratings.dislikes ? ratings.dislikes : 0}</div>
            <img src={themeContext.name === "light" ? dislike.default : dislikeDark.default} alt="dislikes" />
          </div>
        </div>
      )}
    </>
  );
}

Likes.propTypes = {
  location: PropTypes.object,
};

export default Likes;
