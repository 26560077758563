import { createSlice } from "@reduxjs/toolkit";
import { FilterTypeKeys, RoamingFilterTypeKeys } from "../components/shared/Constants";

export const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    locations: [],
    filteredLocations: [],
    roamingLocations: [],
    filteredRoamingLocations: [],
    currentLocation: null,
    userPosition: null,
  },
  reducers: {
    setCurrentLocation: (state, { payload }) => {
      state.currentLocation = payload;
    },
    updateLocations: (state, { payload }) => {
      state.locations = payload ?? [];
    },
    updateRoamingLocations: (state, { payload }) => {
      state.roamingLocations = payload;
    },
    setFilteredLocations: (state, { payload }) => {
      state.filteredLocations = payload ?? [];
    },
    setFilteredRoamingLocations: (state, { payload }) => {
      state.filteredRoamingLocations = payload ?? [];
    },
    setUserPosition: (state, { payload }) => {
      state.userPosition = payload;
    },
    resetRoamingLocations: (state) => {
      state.roamingLocations = [];
      state.filteredRoamingLocations = [];
    },
  },
});

export const {
  updateLocations,
  setFilteredLocations,
  setCurrentLocation,
  setUserPosition,
  updateRoamingLocations,
  setFilteredRoamingLocations,
  resetRoamingLocations,
} = locationsSlice.actions;

const filterLocations = (filters) => async (dispatch, getState) => {
  const { chargerTypes, minChargingSpeed, filterTypes } = filters;
  const locations = getState().locations.locations;

  if (!locations?.length) {
    return;
  }

  const result = locations
    // chargerTypes filtering
    .filter((location) => {
      const locationPlugTypes = location?.evses.map((evse) => evse.plugType.toLowerCase());
      return locationPlugTypes.some((plugType) => chargerTypes.includes(plugType));
    })
    // charging speed filtering
    .filter((location) => {
      const powerSpeedLimit = minChargingSpeed * 1000;
      return location?.evses.every((evse) => evse.power >= powerSpeedLimit);
    })
    // quick filters: public only
    .filter((location) => {
      if (filterTypes.includes(FilterTypeKeys.PUBLIC_ONLY)) {
        return location.usageType.toLowerCase() === "public";
      }

      return true;
    })
    // quick filters: mobiliti only
    .filter((location) => {
      if (filterTypes.includes(FilterTypeKeys.MOBILITI)) {
        return (
          location?.ocpi &&
          location.ocpi.stationId.countryCode === process.env.REACT_APP_MOBILITI_COUNTRY_CODE &&
          location.ocpi.stationId.partyId === process.env.REACT_APP_MOBILITI_PARTY_ID
        );
      }

      return true;
    })
    // quick filters: AC, DC
    .filter((location) => {
      if (filterTypes.includes(FilterTypeKeys.AC_CHARGER) && filterTypes.includes(FilterTypeKeys.DC_CHARGER)) {
        // has AC or DC
        return location.evses.some((charger) => charger.currentType === "AC" || charger.currentType === "DC");
      } else if (filterTypes.includes(FilterTypeKeys.AC_CHARGER) && !filterTypes.includes(FilterTypeKeys.DC_CHARGER)) {
        // has AC
        return location.evses.some((charger) => charger.currentType === "AC");
      } else if (!filterTypes.includes(FilterTypeKeys.AC_CHARGER) && filterTypes.includes(FilterTypeKeys.DC_CHARGER)) {
        // has DC
        return location.evses.some((charger) => charger.currentType === "DC");
      }

      return true;
    })
    // quick filters: cable
    .filter((location) => {
      if (filterTypes.includes(FilterTypeKeys.WITH_CABLE)) {
        return location.evses.some((charger) => charger.cableAttached);
      }

      return true;
    })
    .map((location) => ({ ...location, hidden: false }));

  dispatch(setFilteredLocations(result));
};

const filterRoamingLocations = (filters) => async (dispatch, getState) => {
  const { roaming, roamingFilterTypes } = filters;
  const { roamingLocations } = getState().locations;

  if (!roamingLocations?.length || !roaming) {
    return;
  }

  const result = roamingLocations
    // quick filters: AC, DC
    .filter((location) => {
      if (
        roamingFilterTypes.includes(RoamingFilterTypeKeys.AC_CHARGER) &&
        roamingFilterTypes.includes(RoamingFilterTypeKeys.DC_CHARGER)
      ) {
        // has AC or DC
        return location.currentType === "AC" || location.currentType === "DC";
      } else if (
        roamingFilterTypes.includes(RoamingFilterTypeKeys.AC_CHARGER) &&
        !roamingFilterTypes.includes(RoamingFilterTypeKeys.DC_CHARGER)
      ) {
        // has AC
        return location.currentType === "AC";
      } else if (
        !roamingFilterTypes.includes(RoamingFilterTypeKeys.AC_CHARGER) &&
        roamingFilterTypes.includes(RoamingFilterTypeKeys.DC_CHARGER)
      ) {
        // has DC
        return location.currentType === "DC";
      }

      return true;
    })
    .map((location) => ({ ...location, hidden: false }));

  dispatch(setFilteredRoamingLocations(result));
};

export const locationActions = {
  filterLocations,
  setCurrentLocation,
  updateLocations,
  updateRoamingLocations,
  setFilteredRoamingLocations,
  filterRoamingLocations,
  resetRoamingLocations,
};

export default locationsSlice.reducer;
