import { createSlice } from "@reduxjs/toolkit";

const getSavedThemeOrDefault = () => {
  return window.localStorage.getItem("theme") ? window.localStorage.getItem("theme") : "light";
};

const getSavedMapTypeOrDefault = () => {
  return window.localStorage.getItem("mapType") ? window.localStorage.getItem("mapType") : "roadmap";
};

const getAutomaticDarkEnabledModeOrDefault = () => {
  return window.localStorage.getItem("automaticDarkModeEnabled")
    ? window.localStorage.getItem("automaticDarkModeEnabled") === "true"
    : true;
};

const getSavedTimedDarkModeSettingsOrDefault = () => {
  return window.localStorage.getItem("timedDarkMode")
    ? JSON.parse(window.localStorage.getItem("timedDarkMode"))
    : {
        enabled: false,
        startHours: 19,
        startMinutes: 0,
        endHours: 8,
        endMinutes: 0,
      };
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    name: getSavedThemeOrDefault(),
    timedDarkMode: getSavedTimedDarkModeSettingsOrDefault(),
    automaticDarkModeEnabled: getAutomaticDarkEnabledModeOrDefault(),
    isSnowing: false,
    winter: false,
    mapType: getSavedMapTypeOrDefault(), // roadmap | satellite | hybrid | terrain
  },
  reducers: {
    setTheme: (state, action) => {
      window.localStorage.setItem("theme", action.payload);
      state.name = action.payload;
    },
    toggleIsSnowing: (state) => {
      state.isSnowing = !state.isSnowing;
    },
    toggleWinter: (state, action) => {
      state.winter = action.payload;
    },
    setMapType: (state, action) => {
      window.localStorage.setItem("mapType", action.payload);
      state.mapType = action.payload;
    },
    updateTimedDarkModeSettings: (state, action) => {
      const newValue = {
        enabled: state.timedDarkMode.enabled,
        startHours: action.payload.startHours ? action.payload.startHours : state.timedDarkMode.startHours,
        startMinutes: action.payload.startMinutes ? action.payload.startMinutes : state.timedDarkMode.startMinutes,
        endHours: action.payload.endHours ? action.payload.endHours : state.timedDarkMode.endHours,
        endMinutes: action.payload.endMinutes ? action.payload.endMinutes : state.timedDarkMode.endMinutes,
      };
      window.localStorage.setItem("timedDarkMode", JSON.stringify(newValue));
      state.timedDarkMode = newValue;
    },
    toggleTimedDarkMode: (state, action) => {
      const newValue = {
        ...state.timedDarkMode,
        enabled: action.payload,
      };
      window.localStorage.setItem("timedDarkMode", JSON.stringify(newValue));
      state.timedDarkMode = newValue;
    },
    toggleAutomaticDarkMode: (state, action) => {
      window.localStorage.setItem("automaticDarkModeEnabled", action.payload);
      state.automaticDarkModeEnabled = action.payload;
    },
  },
});

export const {
  setTheme,
  toggleIsSnowing,
  toggleWinter,
  setMapType,
  updateTimedDarkModeSettings,
  toggleTimedDarkMode,
  toggleAutomaticDarkMode,
} = themeSlice.actions;

export default themeSlice.reducer;
