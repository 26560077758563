import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { updateFilters } from "../../../reducers/filtersReducer";
import { ChargerType } from "../../shared/Constants";
import "./ChargerTypes.scss";
import { filterValues } from "./FiltersModal";

function ChargerTypes() {
  const dispatch = useDispatch();
  const { chargerTypes } = useSelector((state) => state.filters);
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const updateChargerTypes = (chargerType) => {
    dispatch(
      updateFilters({
        chargerTypes: filterValues(chargerTypes, chargerType),
      })
    );
  };

  return (
    <>
      <h2>{t("filters.chargerTypes")}</h2>
      <div className="chargerTypeWrapper">
        {Object.values(ChargerType).map(({ key, light, dark }) => (
          <img
            alt="filter type"
            key={key}
            onClick={() => updateChargerTypes(key)}
            style={{
              background: chargerTypes.includes(key)
                ? themeContext.name === "light"
                  ? "white"
                  : "#2b2b2b"
                : themeContext.name === "light"
                ? ""
                : "",
              boxShadow:
                chargerTypes.includes(key) && themeContext.name === "light"
                  ? "0px 4px 15px rgba(107, 110, 112, 0.15)"
                  : "",
              border: chargerTypes.includes(key)
                ? themeContext.name === "light"
                  ? "1px solid rgb(0, 0, 0, 0)"
                  : "1px solid rgb(0, 0, 0, 0)"
                : themeContext.name === "light"
                ? "1px solid #ECF2F8"
                : "1px solid rgb(56 56 56)",
            }}
            className="chargerTypeIcon"
            src={
              chargerTypes.includes(key)
                ? themeContext.name === "light"
                  ? light.icon.default
                  : dark.icon.default
                : themeContext.name === "light"
                ? light.iconDisabled.default
                : dark.iconDisabled.default
            }
          />
        ))}
      </div>
    </>
  );
}

export default ChargerTypes;
