// Filters
export const acChargers = require("./filters/ac_chargers.svg");
export const acChargersDisabled = require("./filters/ac_chargers_disabled.svg");
export const countriesIcon = require("./filters/countries.svg");
export const dcChargers = require("./filters/dc_chargers.svg");
export const dcChargersDisabled = require("./filters/dc_chargers_disabled.svg");
export const freeOnly = require("./filters/free_only.svg");
export const freeOnlyDisabled = require("./filters/free_only_disabled.svg");
export const freeParking = require("./filters/free_parking.svg");
export const freeParkingDisabled = require("./filters/free_parking_disabled.svg");
export const mobilitiCharger = require("./filters/mobiliti_charger.svg");
export const mobilitiChargerDisabled = require("./filters/mobiliti_charger_disabled.svg");
export const publicCharger = require("./filters/public_charger.svg");
export const publicChargerDisabled = require("./filters/public_charger_disabled.svg");
export const roamingOnly = require("./filters/roaming_only.svg");
export const roamingOnlyDisabled = require("./filters/roaming_only_disabled.svg");
export const withCable = require("./filters/with_cable.svg");
export const withCableDisabled = require("./filters/with_cable_disabled.svg");

// Charger connector types
export const cee = require("./charger_types/cee.svg");
export const ceeSmall = require("./charger_types/cee_small.svg");
export const ceeSmallDark = require("./charger_types/cee_small_dark.svg");
export const ceeDisabled = require("./charger_types/cee_disabled.svg");
export const ceeDark = require("./charger_types/cee_dark.svg");
export const ceeDarkDisabled = require("./charger_types/cee_dark_disabled.svg");
export const chademo = require("./charger_types/chademo.svg");
export const chademoSmall = require("./charger_types/chademo_small.svg");
export const chademoSmallDark = require("./charger_types/chademo_small_dark.svg");
export const chademoDisabled = require("./charger_types/chademo_disabled.svg");
export const chademoDark = require("./charger_types/chademo_dark.svg");
export const chademoDarkDisabled = require("./charger_types/chademo_dark_disabled.svg");
export const ccs = require("./charger_types/ccs.svg");
export const ccsSmall = require("./charger_types/ccs_small.svg");
export const ccsSmallDark = require("./charger_types/ccs_small_dark.svg");
export const ccsDisabled = require("./charger_types/ccs_disabled.svg");
export const ccsDark = require("./charger_types/css_dark.svg");
export const ccsDarkDisabled = require("./charger_types/css_dark_disabled.svg");
export const schuko = require("./charger_types/schuko.svg");
export const schukoSmall = require("./charger_types/schuko_small.svg");
export const schukoSmallDark = require("./charger_types/schuko_small_dark.svg");
export const schukoDisabled = require("./charger_types/schuko_disabled.svg");
export const schukoDark = require("./charger_types/schuko_dark.svg");
export const schukoDarkDisabled = require("./charger_types/schuko_dark_disabled.svg");
export const tesla = require("./charger_types/tesla.svg");
export const teslaSmall = require("./charger_types/tesla_small.svg");
export const teslaSmallDark = require("./charger_types/tesla_small_dark.svg");
export const teslaDisabled = require("./charger_types/tesla_disabled.svg");
export const teslaDark = require("./charger_types/tesla_dark.svg");
export const teslaDarkDisabled = require("./charger_types/tesla_dark_disabled.svg");
export const type2 = require("./charger_types/type2.svg");
export const type2Small = require("./charger_types/type2_small.svg");
export const type2SmallDark = require("./charger_types/type2_small_dark.svg");
export const type2Disabled = require("./charger_types/type2_disabled.svg");
export const type2Dark = require("./charger_types/type2_dark.svg");
export const type2DarkDisabled = require("./charger_types/type2_dark_disabled.svg");

// Markers
export const emobiAcGyors = require("./pins/emobi_ac_gyors.svg");
export const emobiDcGyors = require("./pins/emobi_dc_gyors.svg");
export const emobiDcVillam = require("./pins/emobi_dc_villam.svg");
export const mobilitiSima = require("./pins/mobiliti_sima.svg");
export const mobilitiDc50 = require("./pins/mobiliti_dc50.svg");
export const mobilitiDc22 = require("./pins/mobiliti_dc22.svg");
export const mobilitiDc70 = require("./pins/mobiliti_dc70.svg");
export const ionity = require("./pins/ionity.svg");
export const normalAcMax22kw = require("./pins/normal_ac_max22kw.svg");
export const privat = require("./pins/private_2.svg");
export const shell = require("./pins/shell.svg");
export const spar = require("./pins/spar.svg");
export const teslaAc = require("./pins/tesla_ac.svg");
export const teslaDc = require("./pins/tesla_dc.svg");
export const villamAcDc = require("./pins/villam_ac_dc.svg");
export const roamingAc = require("./pins/roaming_ac.svg");
export const roamingDc = require("./pins/roaming_dc.svg");

// Other buttons
export const like = require("./like.svg");
export const likeDark = require("./dark_mode/like.svg");
export const dislike = require("./dislike.svg");
export const dislikeDark = require("./dark_mode/dislike.svg");
export const comment = require("./comment.svg");
export const commentDark = require("./dark_mode/comment.svg");
export const waze = require("./waze.svg");
export const manufacturer = require("./manufacturer.svg");
export const openingHours = require("./openingHours.svg");
export const pin = require("./pin.svg");
export const operator = require("./operator.svg");
export const lightning = require("./lightning_yellow.svg");
