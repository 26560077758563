import Checkbox from "rc-checkbox";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ThemeContext } from "styled-components";
import { countriesIcon, roamingOnly, roamingOnlyDisabled } from "../../../assets/img";
import downwardArrow from "../../../assets/img/downward_arrow.svg";
import downwardArrowDark from "../../../assets/img/downward_arrow_dark.svg";
import upwardArrow from "../../../assets/img/upward_arrow.svg";
import upwardArrowDark from "../../../assets/img/upward_arrow_dark.svg";
import api from "../../../config/api";
import useCountries from "../../../hooks/useCountries";
import { filterActions } from "../../../reducers/filtersReducer";
import { locationActions } from "../../../reducers/locationsReducer";
import MobilitiToggle from "../../shared/elements/MobilitiToggle";
import { getFilterTypeStyle } from "./FilterTypes";
import "./RoamingToggle.scss";

function RoamingToggle() {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [areCountriesShown, setAreCountriesShown] = useState(false);
  const [countriesLoading, setCountriesLoading] = useState(false);
  const { getCountryName } = useCountries();

  const { roaming: isRoaming, roamingCountries, selectedRoamingCountries } = useSelector((state) => state.filters);

  const toggleRoaming = () => {
    dispatch(filterActions.updateRoamingFilters({ roaming: !isRoaming }));
  };

  const toggleCountries = () => {
    setAreCountriesShown((prevState) => !prevState);
  };

  const addOrRemoveCountry = (country) => {
    // performance issues, use only single select
    let countriesList = selectedRoamingCountries.includes(country) ? [] : [country];

    dispatch(
      filterActions.updateRoamingFilters({
        selectedRoamingCountries: countriesList,
      })
    );
  };

  const fetchRoamingCountries = useCallback(() => {
    if (!roamingCountries.length && !countriesLoading) {
      setCountriesLoading(true);

      api.fetchRoamingCountries().then(({ data }) => {
        const countriesWithLabels = data.map(({ isoCode }) => ({
          countryCode: isoCode,
          label: getCountryName(isoCode),
        }));

        countriesWithLabels.sort((a, b) => a.label.localeCompare(b.label));
        dispatch(filterActions.setRoamingCountries(countriesWithLabels));
        setCountriesLoading(false);
      });
    }
  }, [roamingCountries.length, dispatch, getCountryName, countriesLoading]);

  const fetchByCountry = useCallback(async () => {
    if (isRoaming && selectedRoamingCountries.length && roamingCountries.length && !countriesLoading) {
      try {
        toastr.info(t("popup.loading"));
        const { data: result } = await api.fetchRoamingByCountry({
          country: selectedRoamingCountries.map((el) => el.countryCode),
        });

        const mappedRoamingLocations = result.map((roamingLocation) => ({
          ...roamingLocation,
          hidden: true,
          roaming: true,
          latitude: parseFloat(roamingLocation.latitude),
          longitude: parseFloat(roamingLocation.longitude),
        }));
        dispatch(locationActions.updateRoamingLocations(mappedRoamingLocations));
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(locationActions.resetRoamingLocations());
    }
  }, [t, dispatch, isRoaming, selectedRoamingCountries, roamingCountries.length, countriesLoading]);

  useEffect(() => {
    fetchByCountry();
  }, [fetchByCountry]);

  useEffect(() => {
    setAreCountriesShown(false);
    if (isRoaming) {
      dispatch(
        filterActions.updateFilters({
          clustering: true,
        })
      );
      fetchRoamingCountries();
    }
  }, [isRoaming, fetchRoamingCountries, dispatch]);

  return (
    <>
      <div className="filterType" style={getFilterTypeStyle(isRoaming, themeContext.name)} onClick={toggleRoaming}>
        <img alt="filter type" src={isRoaming ? roamingOnly.default : roamingOnlyDisabled.default} />
        <div className={`label ${!isRoaming ? "disabled" : ""}`}>{t("filters.types.roaming")}</div>
        <div className="toggle">
          <MobilitiToggle value={isRoaming} />
        </div>
      </div>

      {isRoaming && (
        <div className="filterType" style={getFilterTypeStyle(isRoaming, themeContext.name)}>
          <div
            style={{
              marginBottom: areCountriesShown ? "15px" : "0",
            }}
            onClick={toggleCountries}>
            <img alt="filter type" src={countriesIcon.default} />
            <div className="label">{t("filters.types.countries")}</div>
            <img
              className="arrow"
              src={
                themeContext.name === "dark"
                  ? areCountriesShown
                    ? upwardArrowDark
                    : downwardArrowDark
                  : areCountriesShown
                  ? upwardArrow
                  : downwardArrow
              }
              alt="countries arrow"
            />
          </div>

          {areCountriesShown && (
            <div className="countries">
              {roamingCountries.map((country) => (
                <React.Fragment key={country.countryCode}>
                  <hr />

                  <div className="country" onClick={() => addOrRemoveCountry(country)} key={country.countryCode}>
                    <Checkbox
                      checked={selectedRoamingCountries.find((c) => c.countryCode === country.countryCode)}
                      className="countryCheckbox"
                    />
                    <div className="countryLabel">{country.label}</div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default RoamingToggle;
