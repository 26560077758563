import PropTypes from "prop-types";
import { acChargers, dcChargers } from "../../../../assets/img";
import "./ConnectorElectricityData.scss";

function ConnectorElectricityData({ currentType, voltage, current }) {
  return (
    <div className="connectorElectricityData">
      <span>
        {currentType === "AC" ? (
          <img src={acChargers.default} alt="AC charger" />
        ) : (
          <img src={dcChargers.default} alt="DC charger" />
        )}
      </span>
      <span>{currentType} | &nbsp;</span>
      <span>{voltage}V | &nbsp;</span>
      <span>{current}A</span>
    </div>
  );
}

ConnectorElectricityData.propTypes = {
  currentType: PropTypes.string,
  voltage: PropTypes.number,
  current: PropTypes.number,
};

export default ConnectorElectricityData;
