import { useContext } from "react";
import { ThemeContext } from "styled-components";
import mobilitiLogoDark from "../../assets/img/dark_mode/mobiliti_logo.svg";
import mobilitiLogo from "../../assets/img/mobiliti_logo.svg";
import "./Header.scss";

function Header() {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      className="header"
      style={{
        boxShadow: `inset 0px 70px 50px -50px ${themeContext.baseColor}`,
      }}
    >
      <img
        alt="header mobiliti logo"
        src={themeContext.name === "light" ? mobilitiLogo : mobilitiLogoDark}
        width="200"
        style={{
          margin: "3px auto 0 auto",
        }}
        height="40"
      />
    </div>
  );
}

export default Header;
