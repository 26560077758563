import appleStoreBadge from "../../../assets/img/apple-store-badge.png";
import googlePlayBadge from "../../../assets/img/google-play-badge.png";
import "./StoreBadges.scss";

function StoreBadges() {
  return (
    <>
      <img
        onClick={() => window.open("https://apps.apple.com/hu/app/mobiliti-nkm-t%C3%B6lt%C5%91pont/id1225050757")}
        alt="apple store badge"
        id="appleStoreBadge"
        src={appleStoreBadge}
        className="d-inline-block align-top"
      />
      <img
        onClick={() => window.open("https://play.google.com/store/apps/details?id=com.tbdb.toltopont")}
        alt="google play store badge"
        id="googlePlayBadge"
        src={googlePlayBadge}
        className="d-inline-block align-top"
      />
    </>
  );
}

export default StoreBadges;
