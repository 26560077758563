import { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentLocation } from "../../reducers/locationsReducer";
import EvseFilterToggle from "../map/EvseFilterToggle";
import LanguageToggle from "../map/LanguageToggle";
import LegendToggle from "../map/LegendToggle";
import Map from "../map/Map";
import MobilitiModeToggle from "../map/MobilitiModeToggle";
import SnowToggle from "../map/SnowToggle";
import AutoCompleteToggle from "../map/autocomplete/AutoCompleteToggle";
import FiltersModal from "../modal/filters/FiltersModal";
import LegendModal from "../modal/legend/LegendModal";
import LocationDetailsModal from "../modal/location-details/LocationDetailsModal";
import EscapeOutside from "../shared/EscapeOutside";

function Content() {
  const dispatch = useDispatch();

  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [showAutoComplete, setShowAutoComplete] = useState(window.innerWidth > 600);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      const { innerWidth } = window;
      if (innerWidth > 600) {
        setShowAutoComplete(true);
      } else if (windowInnerWidth > 600 && innerWidth <= 600) {
        setShowAutoComplete(false);
      }
      setWindowInnerWidth(innerWidth);
    };
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [windowInnerWidth]);

  const handleClose = () => {
    dispatch(setCurrentLocation(null));
  };

  const openFilters = () => {
    setShowFiltersModal(true);
  };

  const toggleAutoCompleteDialog = () => {
    setShowAutoComplete(!showAutoComplete);
  };

  const openLegend = () => {
    setShowLegend(true);
  };

  const closeLegend = () => {
    setShowLegend(false);
  };

  const closeFilters = () => {
    setShowFiltersModal(false);
  };

  return (
    <div className="content">
      <Map showAutoComplete={showAutoComplete} onAutoCompleteClose={toggleAutoCompleteDialog} />

      {window.innerWidth <= 600 && !showAutoComplete && (
        <AutoCompleteToggle openAutoCompleteDialog={toggleAutoCompleteDialog} />
      )}
      {(window.innerWidth > 600 || !showAutoComplete) && <LegendToggle openLegend={openLegend} />}
      {(window.innerWidth > 600 || !showAutoComplete) && <SnowToggle />}
      {(window.innerWidth > 600 || !showAutoComplete) && <LanguageToggle />}
      {(window.innerWidth > 600 || !showAutoComplete) && <EvseFilterToggle openFilters={openFilters} />}
      {(window.innerWidth > 600 || !showAutoComplete) && <MobilitiModeToggle />}

      <EscapeOutside onEscapeOutside={closeLegend}>
        <LegendModal show={showLegend} onHide={closeLegend} />
      </EscapeOutside>
      <EscapeOutside onEscapeOutside={closeFilters}>
        <FiltersModal show={showFiltersModal} onHide={closeFilters} />
      </EscapeOutside>
      {window.innerWidth > 600 ? (
        <EscapeOutside onEscapeOutside={handleClose}>
          <LocationDetailsModal />
        </EscapeOutside>
      ) : (
        <LocationDetailsModal />
      )}
    </div>
  );
}

export default Content;
