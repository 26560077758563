import { GoogleMap, LoadScript, MarkerClusterer } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { setUserPosition } from "../../reducers/locationsReducer";
import { clustererOptions } from "../shared/Constants";
import { dark, light } from "./GoogleMapStyles";
import "./Map.scss";
import { getIcon } from "./MapIconHelper";
import MobilitiMarker from "./MobilitiMarker";
import MobilitiAutoComplete from "./autocomplete/MobilitiAutoComplete";

const libraries = ["places"];
const mapsApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const center = {
  lat: 47.497913,
  lng: 19.040236,
}; // this is Budapest
const defaultMapZoomLevel = 13;

function Map(props) {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { filteredLocations, filteredRoamingLocations, currentLocation } = useSelector((state) => state.locations);
  const { clustering } = useSelector((state) => state.filters);
  const { isSnowing, mapType } = useSelector((state) => state.theme);

  const [zoom, setZoom] = useState(defaultMapZoomLevel);
  const [map, setMap] = useState(null);
  const [clusterer, setClusterer] = useState(null);

  const onLoad = useCallback(
    function callback(mapInstance) {
      const bounds = new window.google.maps.LatLngBounds();
      mapInstance.fitBounds(bounds);
      mapInstance.setOptions({
        zoomControl: window.innerWidth > 600,
        streetViewControl: window.innerWidth > 600,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        styles: themeContext.name === "light" ? light : dark,
        fullscreenControl: false,
        mapTypeControl: false,
      });
      mapInstance.setMapTypeId(window.google.maps.MapTypeId[mapType.toUpperCase()]);

      setTimeout(() => {
        mapInstance.setCenter(center);

        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        const success = ({ coords }) => {
          const position = {
            lat: Number(coords.latitude),
            lng: Number(coords.longitude),
          };
          mapInstance.setCenter(position);
          dispatch(setUserPosition(position));
        };
        const error = () => {
          dispatch(setUserPosition(center));
        };

        navigator.geolocation.getCurrentPosition(success, error, options);
        setMap(mapInstance);
      }, 1000);
    },
    [dispatch, mapType, themeContext.name]
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const onPlaceChanged = (location) => {
    map.setCenter(location);
    setZoom(defaultMapZoomLevel);
    if (window.innerWidth <= 600) {
      onAutoCompleteClose();
    }
  };

  const onAutoCompleteClose = () => {
    props.onAutoCompleteClose();
  };

  const onZoomChange = () => {
    if (map) {
      setZoom(map.zoom);
    }
  };

  const onMarkerClustererLoad = (clusterer) => {
    setClusterer(clusterer);
  };

  const onClustererClick = (e) => {
    map.setCenter({
      lat: e.center.lat(),
      lng: e.center.lng(),
    });
    setZoom(15);
  };

  useEffect(() => {
    if (map) {
      map.setOptions({
        styles: themeContext.name === "light" ? light : dark,
      });
    }
  }, [themeContext, map]);

  useEffect(() => {
    if (map) {
      map.setMapTypeId(window.google.maps.MapTypeId[mapType.toUpperCase()]);
    }
  }, [mapType, map]);

  useEffect(() => {
    if (map && currentLocation && map.zoom <= defaultMapZoomLevel) {
      map.setCenter({
        lat: Number(currentLocation.latitude),
        lng: Number(currentLocation.longitude),
      });
      setZoom(defaultMapZoomLevel);
    }
  }, [currentLocation, map]);

  useEffect(() => {
    if (clusterer) {
      clusterer.repaint();
    }
  }, [filteredLocations, clustering, clusterer, filteredRoamingLocations]);

  return (
    <>
      <LoadScript libraries={libraries} googleMapsApiKey={mapsApiKey}>
        <GoogleMap
          mapContainerClassName={!currentLocation ? "googleMap" : "googleMap blurred"}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onZoomChanged={onZoomChange}
          onUnmount={onUnmount}
        >
          {props.showAutoComplete && (
            <MobilitiAutoComplete onPlaceChanged={onPlaceChanged} onClose={onAutoCompleteClose} />
          )}
          {clustering && (
            <MarkerClusterer onLoad={onMarkerClustererLoad} options={clustererOptions} onClick={onClustererClick}>
              {(clusterer) => (
                <>
                  {[...filteredLocations, ...filteredRoamingLocations].map((location, index) => (
                    <MobilitiMarker
                      clusterer={clusterer}
                      icon={getIcon(location, isSnowing)}
                      location={{ ...location }}
                      key={index}
                    />
                  ))}
                </>
              )}
            </MarkerClusterer>
          )}
          {!clustering && (
            <>
              {[...filteredLocations, ...filteredRoamingLocations].map((location, index) => (
                <MobilitiMarker icon={getIcon(location, isSnowing)} location={{ ...location }} key={index} />
              ))}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
}

Map.propTypes = {
  onAutoCompleteClose: PropTypes.func.isRequired,
  showAutoComplete: PropTypes.bool.isRequired,
};

export default Map;
