import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import flagHU from "../../assets/img/flag_hu.svg";
import flagUK from "../../assets/img/flag_uk.svg";
import { updateLanguage } from "../../reducers/filtersReducer";
import { Language } from "../shared/Constants";
import "./LanguageToggle.scss";

function LanguageToggle() {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = useSelector((state) => state.filters);

  const changeLanguage = () => {
    const newLang = language === Language.HU ? Language.EN : Language.HU;
    i18n.changeLanguage(newLang).then(() => {
      dispatch(updateLanguage(newLang));
    });
  };

  return (
    <>
      <img
        alt="language toggle icon"
        src={language === Language.HU ? flagHU : flagUK}
        className="languageToggleIcon"
        style={{
          backgroundColor: themeContext.name === "light" ? "white" : "rgb(28, 28, 30)",
        }}
        onClick={changeLanguage}
      />
    </>
  );
}

export default LanguageToggle;
