import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { withCable, withCableDisabled } from "../../../../assets/img";
import "./ConnectorIsWithCable.scss";

function ConnectorIsWithCable({ cableAttached }) {
  const { t } = useTranslation();

  return (
    <div className="connectorIsWithCable">
      <span>
        {cableAttached ? (
          <img src={withCable.default} alt="with cable" />
        ) : (
          <img src={withCableDisabled.default} alt="without cable" />
        )}
      </span>
      <span>{cableAttached ? t("location.withCable") : t("location.withoutCable")}</span>
    </div>
  );
}

ConnectorIsWithCable.propTypes = {
  cableAttached: PropTypes.any,
};

export default ConnectorIsWithCable;
