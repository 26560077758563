import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import { useTranslation } from "react-i18next";
import "./LegendItem.scss";

function LegendItem({ icon, header, subHeader }) {
  const { t } = useTranslation();

  return (
    <div className="legendItem">
      <img src={icon} alt={header} />
      <div>
        <span className="legendItemHeader">{t(header)}</span>
        <br />
        <span className="legendItemSubHeader">{t(subHeader)}</span>
      </div>
    </div>
  );
}

LegendItem.propTypes = {
  icon: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
};

export default LegendItem;
