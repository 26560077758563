import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "../config/api";
import { locationActions } from "../reducers/locationsReducer";

const useLocationFilters = () => {
  const dispatch = useDispatch();
  const windowLocation = useLocation();
  const [latestVersion, setLatestVersion] = useState();

  const filters = useSelector((state) => state.filters);
  const { locations, roamingLocations } = useSelector((state) => state.locations);

  const getLocationIdFromPath = (path) => {
    const segment = "/location/";
    const segmentIndex = path.indexOf("/location/");
    return segmentIndex < 0 ? undefined : path.slice(segmentIndex + segment.length, path.length);
  };

  const loadLocationFromPathIfPresent = useCallback(
    (locations) => {
      const locationId = getLocationIdFromPath(windowLocation.pathname);
      if (locationId) {
        const persistedLocation = locations.find((location) => location.id === locationId);
        dispatch(locationActions.setCurrentLocation(persistedLocation));
      }
    },
    [dispatch, windowLocation.pathname]
  );

  const createLocationMarkers = useCallback(
    (locations) => {
      const newLocations = locations.map((location) => ({
        ...location,
        hidden: true, // filtering happens inside reducers
      }));

      loadLocationFromPathIfPresent(newLocations);
      dispatch(locationActions.updateLocations(newLocations));
    },
    [dispatch, loadLocationFromPathIfPresent]
  );

  const fetchLocationData = useCallback(async () => {
    try {
      const { data: version } = await api.fetchOwnAndForeignLocationsVersion();

      if (!latestVersion || new Date(version) > new Date(latestVersion)) {
        setLatestVersion(version);

        const { data: fetchedLocations } = await api.fetchOwnAndForeignLocations();
        createLocationMarkers(fetchedLocations);
      }
    } catch (e) {
      console.log("Error fetching locations: ", { error: e });
    }
  }, [createLocationMarkers, latestVersion]);

  useEffect(() => {
    if (locations && filters) {
      dispatch(locationActions.filterLocations(filters));
    }

    if (roamingLocations && filters) {
      dispatch(locationActions.filterRoamingLocations(filters));
    }
  }, [dispatch, filters, locations, roamingLocations]);

  useEffect(() => {
    fetchLocationData();
  }, [fetchLocationData]);
};

export default useLocationFilters;
