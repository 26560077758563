import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../reducers/themeReducer";

export function useAutoDarkMode() {
  const { automaticDarkModeEnabled } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const media = window.matchMedia("(prefers-color-scheme: dark)");
    if (automaticDarkModeEnabled) {
      dispatch(setTheme(media.matches ? "dark" : "light"));
    }
  }, [automaticDarkModeEnabled, dispatch]);
}
