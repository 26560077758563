import PropTypes from "prop-types";
import searchIcon from "../../../assets/img/search_icon.svg";
import "./AutoCompleteToggle.scss";

function AutoCompleteToggle({ openAutoCompleteDialog }) {
  return <img alt="autocomplete icon" src={searchIcon} className="autoCompleteIcon" onClick={openAutoCompleteDialog} />;
}

AutoCompleteToggle.propTypes = {
  openAutoCompleteDialog: PropTypes.func.isRequired,
};

export default AutoCompleteToggle;
