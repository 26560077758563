import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import filtersReducer from "./reducers/filtersReducer";
import locationsReducer from "./reducers/locationsReducer";
import themeReducer from "./reducers/themeReducer";

export default configureStore({
  reducer: {
    filters: filtersReducer,
    locations: locationsReducer,
    theme: themeReducer,
    toastr: toastrReducer,
  },
  middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
});
