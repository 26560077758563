import axios from "./axios";

const basePath = process.env.REACT_APP_API_URL || window.location.origin;
const ocpiLocationApiUrl = `${basePath}/${process.env.REACT_APP_OCPI_LOCATION_MS_NAME}`;
const usersApiUrl = `${basePath}/${process.env.REACT_APP_USERS_MS_NAME}`;

const ocpiLocationEndpoints = {
  roamingV3: `${ocpiLocationApiUrl}/api/v3/locations/roaming`,
  roamingV2: `${ocpiLocationApiUrl}/api/v2/locations/roaming`,
  roamingCountries: `${ocpiLocationApiUrl}/api/v2/locations/roaming-countries`,
  locations: `${ocpiLocationApiUrl}/api/v2/public/locations`,
  ownAndForeignLocations: `${ocpiLocationApiUrl}/api/v1/own-and-foreign-locations`,
  locationComments: `${ocpiLocationApiUrl}/api/v2/public/own-or-foreign-locations/comments`,
  locationPictures: `${ocpiLocationApiUrl}/api/v2/public/own-or-foreign-locations/pictures`,
};

const usersEndpoints = {
  legalDocuments: `${usersApiUrl}/api/v1/public/documents/legal`,
};

const api = {
  fetchRoamingLocationDetails: ({ countryCode, partyId, locationId }) =>
    axios.get(
      // `/ocpi-location/api/v2/locations/roaming/${countryCode}/${partyId}/${locationId}`,
      `${ocpiLocationEndpoints.roamingV2}/${countryCode}/${partyId}/${locationId}`
    ),
  fetchRoamingByCountry: ({ country }) =>
    axios.get(
      // `/ocpi-location/api/v3/locations/roaming?includeCountries=NLD`,
      `${ocpiLocationEndpoints.roamingV3}?includeCountries=${country}`
    ),
  fetchRoamingCountries: () =>
    axios.get(
      // `/ocpi-location/api/v2/locations/roaming-countries`,
      ocpiLocationEndpoints.roamingCountries
    ),
  fetchLocation: ({ countryCode, partyId, locationId, reduced }) =>
    axios.get(
      // `/ocpi-location/api/v2/public/locations/${countryCode}/${partyId}/${locationId}?reduced=true`
      `${ocpiLocationEndpoints.locations}/${countryCode}/${partyId}/${locationId}`,
      { params: { reduced } }
    ),
  fetchOwnAndForeignLocations: () =>
    axios.get(
      // `/ocpi-location/api/v1/own-and-foreign-locations`
      ocpiLocationEndpoints.ownAndForeignLocations
    ),
  fetchOwnAndForeignLocationsVersion: () =>
    axios.get(
      // `/ocpi-location/api/v1/own-and-foreign-locations/version`
      `${ocpiLocationEndpoints.ownAndForeignLocations}/version`
    ),
  fetchLocationComments: ({ locationId }) =>
    axios.get(
      // `/ocpi-location/api/v2/public/own-or-foreign-locations/comments/${locationId}`
      `${ocpiLocationEndpoints.locationComments}/${locationId}`
    ),
  fetchLocationPictures: ({ locationId }) =>
    axios.get(
      // `/ocpi-location/api/v2/public/own-or-foreign-locations/pictures/{locationId}s`
      `${ocpiLocationEndpoints.locationPictures}/${locationId}`
    ),
  fetchLegalDocuments: () =>
    axios.get(
      // `/users/api/v1/public/documents/legal`,
      usersEndpoints.legalDocuments
    ),
};

export default api;
