import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import closeIcon from "../../../assets/img/close_icon.svg";
import closeIconDark from "../../../assets/img/dark_mode/close_icon.svg";
import magnifyingGlassIcon from "../../../assets/img/magnifying_glass.svg";
import "./MobilitiAutoComplete.scss";
import MobilitiAutoCompleteInputField from "./MobilitiAutoCompleteInputField";
import MobilitiAutoCompleteResults from "./MobilitiAutoCompleteResults";

function MobilitiAutoComplete(props) {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const [placesAutocompleteService, setPlacesAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [currentSearchValue, setCurrentSearchValue] = useState("");

  useEffect(() => {
    const container = document.getElementById("placeholderForGooglePlacesService");
    const service = new window.google.maps.places.PlacesService(container);
    const autocomplete = new window.google.maps.places.AutocompleteService();

    setPlacesAutocompleteService(autocomplete);
    setPlacesService(service);
  }, []);

  const onPlaceSelected = (placeId) => {
    const request = { placeId };
    placesService.getDetails(request, (locationResult, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        props.onPlaceChanged({
          lat: locationResult.geometry.location.lat(),
          lng: locationResult.geometry.location.lng(),
        });
      }
    });
  };

  const deleteCurrentSearchValue = () => {
    setCurrentSearchValue("");
    setPredictions([]);
  };

  const fetchResults = (e) => {
    const targetValue = e.target.value;
    if (targetValue) {
      setCurrentSearchValue(targetValue);
      const request = {
        input: targetValue,
        componentRestrictions: {
          country: "hu",
        },
      };
      placesAutocompleteService.getPlacePredictions(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(results);
        }
      });
    } else {
      deleteCurrentSearchValue();
    }
  };

  return (
    <>
      <div id="placeholderForGooglePlacesService" />

      <img alt="close icon" src={closeIcon} onClick={props.onClose} className="closeIcon" />
      <div
        className="searchBox"
        style={{
          background: themeContext.name === "light" ? "#FFFFFF" : "#282828",
        }}
      >
        <p
          style={{
            color: themeContext.name === "light" ? "#183254" : "#FFFFFF",
          }}
        >
          {t("searchBox.title")}
        </p>

        {currentSearchValue && (
          <img
            alt="close icon"
            src={themeContext.name === "light" ? closeIcon : closeIconDark}
            onClick={deleteCurrentSearchValue}
            className="closeIconForInputField"
          />
        )}

        {!currentSearchValue && (
          <img
            alt="close icon"
            src={magnifyingGlassIcon}
            onClick={deleteCurrentSearchValue}
            className="magnifyingGlassForInputField"
          />
        )}

        <MobilitiAutoCompleteInputField
          themeContext={themeContext}
          onChange={fetchResults}
          value={currentSearchValue}
          type="text"
          placeholder={t("searchBox.placeholder")}
        />
        <MobilitiAutoCompleteResults
          onPlaceSelected={onPlaceSelected}
          currentSearchValue={currentSearchValue}
          predictions={predictions}
        />
      </div>
    </>
  );
}

export default MobilitiAutoComplete;

MobilitiAutoComplete.propTypes = {
  onPlaceChanged: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
