import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { filterActions } from "../../../reducers/filtersReducer";
import { locationActions } from "../../../reducers/locationsReducer";
import ThemeSwitcher from "../../shared/ThemeSwitcher";
import { Hr } from "../../shared/elements/Hr";
import GenericModal from "../GenericModal";
import ChargerTypes from "./ChargerTypes";
import ClusteringToggle from "./ClusteringToggle";
import FilterTypes from "./FilterTypes";
import "./FiltersModal.scss";
import MapTypeSwitcher from "./MapTypeSwitcher";
import MinChargingSpeed from "./MinChargingSpeed";
import { StyledFiltersResult } from "./StyledFiltersResult";

export const filterValues = (values, value) => {
  let filteredValues = [...values];
  if (filteredValues.indexOf(value) > -1) {
    filteredValues = filteredValues.filter((item) => item !== value);
  } else {
    filteredValues.push(value);
  }
  return filteredValues;
};

function FiltersModal(props) {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { locations, filteredLocations, filteredRoamingLocations } = useSelector((state) => state.locations);
  const filters = useSelector((state) => state.filters);
  const [amountOfResults, setAmountOfResults] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const filteredAmount = (filteredLocations?.length ?? 0) + (filteredRoamingLocations?.length ?? 0);
    setAmountOfResults(filteredAmount);
  }, [filteredLocations?.length, filteredRoamingLocations?.length, dispatch]);

  const resetFilters = useCallback(() => {
    dispatch(filterActions.resetFilters());
  }, [dispatch]);

  useEffect(() => {
    if (locations && filters) {
      dispatch(locationActions.filterLocations(filters));
    }
  }, [locations, filters, dispatch]);

  const modalContent = (
    <>
      <h1>{t("filters.title")}</h1>
      <Hr themeContext={themeContext} />
      <ChargerTypes />
      <Hr themeContext={themeContext} />
      <MinChargingSpeed />
      <Hr themeContext={themeContext} />
      <FilterTypes />
      <Hr themeContext={themeContext} />
      <ThemeSwitcher />
      <Hr themeContext={themeContext} />
      <MapTypeSwitcher />
      <Hr themeContext={themeContext} />
      <ClusteringToggle />
    </>
  );

  const footerContent = (
    <StyledFiltersResult
      amountOfResults={amountOfResults}
      onFilter={props.onHide}
      resetLabel={t("filters.reset")}
      resultsLabel={t("filters.results")}
      onReset={resetFilters}
    />
  );

  return <GenericModal show={props.show} onHide={props.onHide} content={modalContent} footer={footerContent} />;
}

FiltersModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default FiltersModal;
