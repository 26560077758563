import "rc-slider/assets/index.css";
import styled from "styled-components";
import MobilitiButton from "../../shared/elements/MobilitiButton";
import "./FiltersModal.scss";

const AmountOfResultsLabel = (amountOfResults, resultsLabel) => (
  <>
    <span>{`${amountOfResults} ${resultsLabel}`}</span>
    <span
      style={{
        paddingLeft: "10px",
      }}
    >
      {" > "}
    </span>
  </>
);

const FiltersResult = ({ className, amountOfResults, onReset, onFilter, resetLabel, resultsLabel }) => (
  <div className={className}>
    <div className="label" onClick={onReset}>
      {resetLabel}
    </div>
    <MobilitiButton
      onClick={onFilter}
      type="mobilitiGreen"
      label={AmountOfResultsLabel(amountOfResults, resultsLabel)}
    />
  </div>
);

export const StyledFiltersResult = styled(FiltersResult)`
  div.label {
    display: inline-block;
    color: #fd6464;
    font-weight: 500;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    button {
      float: none;
    }
    div.label {
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 600px) {
    button {
      float: right;
      margin-top: -9px;
    }
  }
`;
