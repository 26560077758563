import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import api from "../../../config/api";
import { setCurrentLocation } from "../../../reducers/locationsReducer";
import GenericModal from "../GenericModal";
import Comments from "./Comments";
import LocationDetailItems from "./LocationDetaiItems";
import "./LocationDetailsModal.scss";
import LocationDetailsTitle from "./LocationDetailsTitle";
import NavigationButtons from "./NavigationButtons";
import Ratings from "./Ratings";
import ReportError from "./ReportError";
import Connectors from "./connectors/Connectors";
import LocationContacts from "./contacts/LocationContacts";

function LocationDetailsModal() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  let { currentLocation } = useSelector((state) => state.locations);

  useEffect(() => {
    if (currentLocation && !currentLocation.roaming) {
      api
        .fetchLocationPictures({ locationId: currentLocation.id })
        .then(({ data }) => {
          const pictureUrls = data.map((picture) => picture.link);
          setPictures(pictureUrls);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [currentLocation]);

  const hideLocationDetails = () => {
    currentLocation = null;
    dispatch(setCurrentLocation(null));
  };

  const modalContent = (selectedLocation) => (
    <>
      <LocationDetailsTitle location={selectedLocation} />

      {!selectedLocation.roaming && (
        <div className="comments-likes-navigation-wrapper">
          <Comments location={selectedLocation} />
          <Ratings location={selectedLocation} />
          <NavigationButtons location={selectedLocation} />
        </div>
      )}

      {selectedLocation.roaming && (
        <div className="comments-likes-navigation-wrapper">
          <NavigationButtons location={selectedLocation} />
        </div>
      )}

      <Connectors location={selectedLocation} />

      {!selectedLocation.roaming && (
        <>
          <ReportError location={selectedLocation} />

          {pictures && pictures.length > 0 && (
            <Carousel>
              {pictures.map((pictureUrl) => (
                <div key={pictureUrl}>
                  <img alt="charger" src={pictureUrl} />
                </div>
              ))}
            </Carousel>
          )}
          <LocationDetailItems location={selectedLocation} />

          {selectedLocation.description && (
            <div>
              <h2>{t("location.description")}</h2>
              <p className="description">
                {i18n.language === "hu" && selectedLocation.description.hu && (
                  <span>{selectedLocation.description.hu}</span>
                )}
                {i18n.language === "en" && selectedLocation.description.en && (
                  <span>{selectedLocation.description.en}</span>
                )}
                {i18n.language === "en" && !selectedLocation.description.en && (
                  <span>{selectedLocation.description.hu}</span>
                )}
              </p>
            </div>
          )}

          <h2>{t("location.address")}</h2>
          <div className="address">
            <div>{selectedLocation.name}</div>
            <div>{selectedLocation.address}</div>
            <div>{selectedLocation.postalCode}</div>
            <div>{selectedLocation.city}</div>
            <div>{selectedLocation.province}</div>
          </div>

          <LocationContacts location={selectedLocation} />
        </>
      )}
    </>
  );

  return (
    <>
      {currentLocation && (
        <div className="LocationDetailsModal">
          <GenericModal show={!!currentLocation} content={modalContent(currentLocation)} onHide={hideLocationDetails} />
        </div>
      )}
    </>
  );
}

export default LocationDetailsModal;
