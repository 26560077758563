import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import autocompleteResultPin from "../../../assets/img/autocomplete_result_pin.svg";
import autocompleteResultPinDark from "../../../assets/img/dark_mode/autocomplete_result_pin.svg";
import { Hr } from "../../shared/elements/Hr";
import { MobilitiAutocompleteResult } from "./MobilitiAutoCompleteResult";
import "./MobilitiAutoCompleteResults.scss";

function MobilitiAutoCompleteResults({ predictions, onPlaceSelected, currentSearchValue }) {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      {predictions.length && (
        <div className="autocompleteResults">
          <Hr themeContext={themeContext} />
          {predictions.map(({ place_id, structured_formatting }) => (
            <div className="autocompleteResult" onClick={() => onPlaceSelected(place_id)} key={place_id}>
              <img
                src={themeContext.name === "light" ? autocompleteResultPin : autocompleteResultPinDark}
                alt="autocomplete result pin"
              />

              <MobilitiAutocompleteResult
                text={structured_formatting.main_text}
                themeContext={themeContext}
                currentSearchValue={currentSearchValue}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default MobilitiAutoCompleteResults;

MobilitiAutoCompleteResults.propTypes = {
  predictions: PropTypes.array,
  onPlaceSelected: PropTypes.func.isRequired,
  currentSearchValue: PropTypes.string,
};
