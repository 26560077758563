import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Contact from "./Contact";

function LocationContacts({ location }) {
  const { t } = useTranslation();

  return (
    <>
      {location.operator && (
        <div>
          <h2>{t("location.contact")}</h2>
          <div className="contact">
            <div>{location.operator.name}</div>

            {location.operator.email && (
              <Contact key={location.operator.email + "email"} contact={location.operator.email} type="email" />
            )}
            {location.operator.phone && (
              <Contact key={location.operator.phone + "phone"} contact={location.operator.phone} type="phone" />
            )}
            {location.operator.website && (
              <Contact key={location.operator.website + "website"} contact={location.operator.website} type="website" />
            )}
          </div>
        </div>
      )}
    </>
  );
}

LocationContacts.propTypes = {
  location: PropTypes.object,
};

export default LocationContacts;
