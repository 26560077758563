import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getSavedLanguageOrDefault } from "./reducers/filtersReducer";

const publicUrl = process.env.PUBLIC_URL;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: () => "hu",
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `${publicUrl}/i18n/{{lng}}.json`,
    },
    lng: getSavedLanguageOrDefault(),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
