import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import filterIconDark from "../../assets/img/dark_mode/filter_icon.svg";
import filterIcon from "../../assets/img/filter_icon.svg";
import "./EvseFilterToggle.scss";

function EvseFilterToggle({ openFilters }) {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <img
        alt="filter icon"
        src={themeContext.name === "light" ? filterIcon : filterIconDark}
        className="filterIcon"
        onClick={openFilters}
      />
    </>
  );
}

EvseFilterToggle.propTypes = {
  openFilters: PropTypes.func.isRequired,
};

export default EvseFilterToggle;
