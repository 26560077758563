import "rc-checkbox/assets/index.css";
import { Provider } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import AppContainer from "./components/layout/AppContainer";
import store from "./store";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
