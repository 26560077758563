import PropTypes from "prop-types";
import "./LocationDetailsTitle.scss";

function LocationDetailsTitle({ location }) {
  return (
    <div className="title">
      {location.roaming && (
        <>
          <span className="main-title">{location.name}</span>
          <br />
          <span className="address">{`${location.postalCode ? location.postalCode + ", " : ""}${location.city}, ${
            location.address
          }`}</span>
        </>
      )}

      {!location.roaming && (
        <>
          {location.label && <span className="label">{location.label}</span>}
          <span className="main-title">{location.name}</span>
          {location.operator && (
            <>
              <br />
              <span className="operator">{location.operator.name}</span>
            </>
          )}
          <br />
          <span className="address">{`${location.postalCode}, ${location.province}, ${location.address}`}</span>
        </>
      )}
    </div>
  );
}

LocationDetailsTitle.propTypes = {
  location: PropTypes.object,
};

export default LocationDetailsTitle;
