import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import "./MobilitiButton.scss";

function MobilitiButton({ type, label, active, onClick, icon }) {
  const themeContext = useContext(ThemeContext);

  const getButtonType = () => {
    switch (type) {
      case "generic":
        return "genericButton";
      case "mobilitiGreen":
        return "mobilitiGreen";
      default:
        return "genericButton";
    }
  };

  const getClassName = () => {
    return `mobilitiButton ${getButtonType()} ${themeContext.name} ${active ? "active" : ""}`;
  };

  return (
    <button className={getClassName()} onClick={onClick}>
      {icon && <img src={icon} alt="button icon" />}
      {label}
    </button>
  );
}

MobilitiButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.any,
};

MobilitiButton.defaultProps = {
  type: "generic",
  active: false,
};

export default MobilitiButton;
