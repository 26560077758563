import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import closeIcon from "../../assets/img/close_icon.svg";
import closeIconDark from "../../assets/img/dark_mode/close_icon.svg";
import "./GenericModalHeader.scss";

function GenericModalHeader({ handleCancel }) {
  const themeContext = useContext(ThemeContext);

  return (
    <img
      alt="close icon"
      src={themeContext.name === "light" ? closeIcon : closeIconDark}
      onClick={handleCancel}
      className="closeModalIcon"
    />
  );
}

GenericModalHeader.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default GenericModalHeader;
