import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import legendIconDark from "../../assets/img/dark_mode/legend_icon.svg";
import legendIcon from "../../assets/img/legend.svg";
import "./LegendToggle.scss";

function LegendToggle({ openLegend }) {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <img
        alt="legend icon"
        src={themeContext.name === "light" ? legendIcon : legendIconDark}
        style={{
          background: themeContext.name === "light" ? "#FFFFFF" : "#1C1C1E",
          border: themeContext.name === "light" ? "" : "1px solid #404044",
        }}
        className="legendIcon"
        onClick={openLegend}
      />
    </>
  );
}

LegendToggle.propTypes = {
  openLegend: PropTypes.func.isRequired,
};

export default LegendToggle;
