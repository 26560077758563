import { createSlice } from "@reduxjs/toolkit";
import { ChargerTypeKeys, FilterTypeKeys, Language } from "../components/shared/Constants";

export const defaultFilters = {
  filterTypes: Object.values(FilterTypeKeys).filter((el) => el !== FilterTypeKeys.WITH_CABLE),
  chargerTypes: Object.values(ChargerTypeKeys),
  minChargingSpeed: 0,
  clustering: false,
  roaming: false,
  roamingFilterTypes: [],
  roamingCountries: [],
  selectedRoamingCountries: [],
};

export const getSavedLanguageOrDefault = () => {
  return window.localStorage.getItem("language") ? window.localStorage.getItem("language") : Language.HU;
};

const getSavedFiltersOrDefault = () => {
  const storedFilters = window.localStorage.getItem("filters")
    ? JSON.parse(window.localStorage.getItem("filters"))
    : {};
  const storedRoamingFilters = window.localStorage.getItem("roamingFilters")
    ? JSON.parse(window.localStorage.getItem("roamingFilters"))
    : {};
  return {
    ...defaultFilters,
    ...storedFilters,
    ...storedRoamingFilters,
  };
};

// NOTE: NKME-8313 feature removed some old charger plug types, we need to keep the localstorage compatible
const onlySelectablePlugTypes = (selectedTypes = []) => {
  const allTypes = defaultFilters.chargerTypes;
  const allSelectable = selectedTypes.every((type) => allTypes.includes(type));
  const result = allSelectable ? selectedTypes : selectedTypes.filter((type) => allTypes.includes(type));
  return result;
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    ...getSavedFiltersOrDefault(),
    language: getSavedLanguageOrDefault(),
  },
  reducers: {
    updateLanguage: (state, { payload }) => {
      localStorage.setItem("language", payload);
      state.language = payload;
    },
    toggleClustering: (state) => {
      state.clustering = !state.clustering;
    },
    updateFilters: (state, { payload }) => {
      const { chargerTypes, filterTypes, minChargingSpeed, clustering } = payload;

      const result = {
        filterTypes: filterTypes ? filterTypes : state.filterTypes,
        chargerTypes: chargerTypes ? onlySelectablePlugTypes(chargerTypes) : state.chargerTypes,
        minChargingSpeed: minChargingSpeed !== undefined ? minChargingSpeed : state.minChargingSpeed,
        clustering: clustering === undefined ? state.clustering : !!clustering,
        language: state.language,
      };

      localStorage.setItem("filters", JSON.stringify(result));
      state.filterTypes = result.filterTypes;
      state.chargerTypes = result.chargerTypes;
      state.minChargingSpeed = result.minChargingSpeed;
      state.clustering = result.clustering;
      state.language = result.language;
    },
    updateRoamingFilters: (state, { payload }) => {
      const result = {
        roaming: payload.roaming ?? true,
        roamingFilterTypes: payload.roaming === false ? [] : payload.roamingFilterTypes ?? state.roamingFilterTypes,
        selectedRoamingCountries:
          payload.roaming === false ? [] : payload.selectedRoamingCountries ?? state.selectedRoamingCountries,
      };
      state.roaming = result.roaming;
      state.roamingFilterTypes = result.roamingFilterTypes;
      state.selectedRoamingCountries = result.selectedRoamingCountries;

      localStorage.setItem("roamingFilters", JSON.stringify(result));
    },
    setRoamingCountries: (state, { payload }) => {
      state.roamingCountries = payload ?? [];
    },

    resetFilters: () => {
      return {
        ...defaultFilters,
        language: getSavedLanguageOrDefault(),
      };
    },
  },
});

export const {
  updateFilters,
  updateRoamingFilters,
  setRoamingCountries,
  toggleClustering,
  updateLanguage,
  resetFilters,
} = filtersSlice.actions;

export const filterActions = {
  updateFilters,
  updateRoamingFilters,
  setRoamingCountries,
  toggleClustering,
  updateLanguage,
  resetFilters,
};

export default filtersSlice.reducer;
