import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ThemeContext } from "styled-components";
import { waze } from "../../../assets/img";
import appleMapsLogo from "../../../assets/img/AppleMaps_logo_1.svg";
import googleMapsIcon from "../../../assets/img/Google_Maps_icon.svg";
import positionIcon from "../../../assets/img/position_icon.svg";
import "./NavigationButtons.scss";

function NavigationButtons({ location }) {
  const themeContext = useContext(ThemeContext);
  const { userPosition } = useSelector((state) => state.locations);
  const { t } = useTranslation();

  const openWithWaze = () => {
    window.open(`https://waze.com/ul?ll=${location.latitude},${location.longitude}&z=10`);
  };

  const openWithGoogleMaps = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`);
  };

  const openWithAppleMaps = () => {
    window.open(`https://maps.apple.com/?ll=${location.latitude},${location.longitude}&t=m`);
  };

  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const getDistance = (p1, p2) => {
    if (p1 && p2 && p1.lat && p2.lat) {
      const R = 6378137; // Earth’s mean radius in meter
      const dLat = rad(p2.lat - p1.lat);
      const dLong = rad(p2.lng - p1.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return (d / 1000).toFixed(1); // returns the distance in km
    }
    return 0;
  };

  return (
    <>
      <div
        className="navigation-indicator"
        style={{
          border: themeContext.name === "light" ? "1px solid #e5edfc" : "1px solid #434343",
        }}
        onClick={() =>
          toastr.confirm(null, {
            disableCancel: true,
            okText: "OK",
            id: `mobiliti-popup-${themeContext.name}`,
            component: () => (
              <div className="navigationPopup">
                {t("popup.chooseNavigation")}
                <div className={`navigationWrapper`}>
                  <div
                    className="navigationType"
                    style={{
                      marginBottom: "15px",
                      marginRight: "22px",
                    }}
                  >
                    <img
                      src={waze.default}
                      alt="waze"
                      style={{
                        width: "61px",
                        marginBottom: "3px",
                        backgroundColor: "rgba(111, 197, 222, 1)",
                      }}
                      onClick={openWithWaze}
                    />
                    <div>Waze</div>
                  </div>
                  <div
                    className="navigationType"
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    <img
                      src={googleMapsIcon}
                      onClick={openWithGoogleMaps}
                      alt="waze"
                      style={{
                        width: "75px",
                      }}
                    />
                    <div>Google Maps</div>
                  </div>
                  <div className="navigationType">
                    <img
                      src={appleMapsLogo}
                      onClick={openWithAppleMaps}
                      alt="waze"
                      style={{
                        width: "70px",
                      }}
                    />
                    <div>Apple Maps</div>
                  </div>
                </div>
              </div>
            ),
          })
        }
      >
        <img src={positionIcon} alt="navigate" />
        {getDistance({ lat: location.latitude, lng: location.longitude }, userPosition)} km
      </div>
    </>
  );
}

NavigationButtons.propTypes = {
  location: PropTypes.object,
};

export default NavigationButtons;
