import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { toggleClustering } from "../../../reducers/filtersReducer";
import { Hr } from "../../shared/elements/Hr";
import MobilitiToggle from "../../shared/elements/MobilitiToggle";
import "./ClusteringToggle.scss";

function ClusteringToggle() {
  const dispatch = useDispatch();
  const { clustering } = useSelector((state) => state.filters);
  const themeContext = useContext(ThemeContext);
  const [isTogglingOn, setIsToggling] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsToggling(clustering);
  }, [clustering]);

  const updateClustering = (newValue) => {
    setIsToggling(newValue);
    setTimeout(() => {
      dispatch(toggleClustering());
    }, 400);
  };

  return (
    <>
      <div className="clusteringToggleWrapper">
        <div className="label">{t("filters.toggleClustering")}</div>
        <MobilitiToggle onChange={updateClustering} value={isTogglingOn} />
      </div>
      <Hr themeContext={themeContext} />
    </>
  );
}

export default ClusteringToggle;
