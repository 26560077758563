import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import errorReportIcon from "../../../assets/img/error_report.svg";
import MobilitiButton from "../../shared/elements/MobilitiButton";
import "./ReportError.scss";

function ReportError({ location }) {
  const { t } = useTranslation();
  const feedbackFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdqY8d4cvNdNndYhcMq_52L2ZZYkmAKdwP9k_mHbj2Unzcq2g";

  const reportError = () => {
    window.open(`${feedbackFormUrl}/viewform?entry.1975704092=${location.name}&entry.366900404=${location.label}`);
  };

  return (
    <div className="reportError">
      <MobilitiButton type="generic" icon={errorReportIcon} label={t("location.reportError")} onClick={reportError} />
    </div>
  );
}

ReportError.propTypes = {
  location: PropTypes.object,
};

export default ReportError;
