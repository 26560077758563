import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import api from "../../../../config/api";
import ConnectorElectricityData from "./ConnectorElectricityData";
import ConnectorImage from "./ConnectorImage";
import ConnectorIsWithCable from "./ConnectorIsWithCable";
import ConnectorTitleWithPower from "./ConnectorTitleWithPower";
import "./Connectors.scss";

function Connectors(props) {
  const [location, setLocation] = useState(props.location);
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const fetchConnectorStatuses = useCallback(
    ({ countryCode, locationId, partyId }) => {
      api
        .fetchLocation({ countryCode, locationId, partyId, reduced: true })
        .then(({ data }) => {
          const locationCopy = JSON.parse(JSON.stringify(location));
          data.evses.forEach(({ status, evseId }) => {
            const index = locationCopy?.evses.findIndex(({ evseId: compareEvseId }) => compareEvseId === evseId);
            locationCopy.evses[index].status = status;
          });
          setLocation(locationCopy);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [location]
  );

  useEffect(() => {
    if (location?.ocpi) {
      fetchConnectorStatuses(location?.ocpi.stationId);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`connectors ${themeContext.name}`}>
      <h2>
        {t("location.chargers")} ({location?.evses.length} {t("common.pcs")})
      </h2>
      {location?.evses.map((charger, index) => (
        <div key={index} className={`connector connector-${charger.status ? charger.status.toLowerCase() : ""}`}>
          <ConnectorImage plugType={charger.plugType} status={charger.status} label={charger.label} />

          <div className="connectorDetailsWrapper">
            <ConnectorTitleWithPower plugType={charger.plugType} power={charger.power} />
            <ConnectorElectricityData
              current={charger.amperage}
              currentType={charger.currentType}
              voltage={charger.voltage}
            />
            <ConnectorIsWithCable cableAttached={charger.cableAttached} />
          </div>
        </div>
      ))}
    </div>
  );
}

Connectors.propTypes = {
  chargers: PropTypes.array,
  location: PropTypes.any,
};

export default Connectors;
