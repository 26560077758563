import styled from "styled-components";
import "./MobilitiAutoCompleteResults.scss";

const AutocompleteResult = ({ className, text, currentSearchValue }) => (
  <div className={className}>
    <span className="boldPart">{text.slice(0, currentSearchValue.length)}</span>
    <span className="normalPart">{text.slice(currentSearchValue.length, text.length)}</span>
  </div>
);

export const MobilitiAutocompleteResult = styled(AutocompleteResult)`
  @media screen and (max-width: 600px) {
    max-width: 80vw;
    display: inline-block;
    vertical-align: top;
    margin-top: 9px;

    .boldPart {
      font-weight: 700;
      color: ${(props) => (props.themeContext.name === "light" ? "#408E8D" : "#52D0CF")};
    }

    .normalPart {
      font-weight: 500;
      color: ${(props) => (props.themeContext.name === "light" ? "inherit" : "white")};
    }
  }

  @media screen and (min-width: 601px) {
    max-width: 300px;
    display: inline-block;
    vertical-align: top;
    margin-top: 9px;

    .boldPart {
      font-weight: 700;
      color: ${(props) => (props.themeContext.name === "light" ? "#408E8D" : "#52D0CF")};
    }

    .normalPart {
      font-weight: 500;
      color: ${(props) => (props.themeContext.name === "light" ? "inherit" : "white")};
    }
  }
`;
