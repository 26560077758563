import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setTheme,
  toggleAutomaticDarkMode,
  toggleTimedDarkMode,
  updateTimedDarkModeSettings,
} from "../../reducers/themeReducer";
import "./ThemeSwitcher.scss";
import MobilitiButton from "./elements/MobilitiButton";

function ThemeSwitcher() {
  const getStartTimeValueOrDefault = () => {
    if (timedDarkMode.enabled) {
      return `${minTwoDigits(timedDarkMode.startHours)}:${minTwoDigits(timedDarkMode.startMinutes)}`;
    }
    return "19:00";
  };

  const getEndTimeValueOrDefault = () => {
    if (timedDarkMode.enabled) {
      return `${minTwoDigits(timedDarkMode.endHours)}:${minTwoDigits(timedDarkMode.endMinutes)}`;
    }
    return "08:00";
  };

  const changeTheme = (value) => {
    dispatch(toggleAutomaticDarkMode(false));
    dispatch(toggleTimedDarkMode(false));
    dispatch(setTheme(value));
  };

  const minTwoDigits = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const updateTimeBasedThemeSettings = (event, type) => {
    if (event) {
      const [hours, minutes] = event.target.value.split(":");

      if (type === "start") {
        setStartTime(event.target.value);
        dispatch(
          updateTimedDarkModeSettings({
            startHours: parseInt(hours),
            startMinutes: parseInt(minutes),
          })
        );
      } else if (type === "end") {
        setEndTime(event.target.value);
        dispatch(
          updateTimedDarkModeSettings({
            endHours: parseInt(hours),
            endMinutes: parseInt(minutes),
          })
        );
      }
    }
  };

  const toggleTimeBasedThemeSettings = () => {
    dispatch(toggleAutomaticDarkMode(false));
    dispatch(toggleTimedDarkMode(!timedDarkMode.enabled));
  };

  const toggleAutoDarkMode = () => {
    dispatch(toggleTimedDarkMode(false));
    dispatch(toggleAutomaticDarkMode(true));
  };

  const { name: theme, timedDarkMode, automaticDarkModeEnabled } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(getStartTimeValueOrDefault());
  const [endTime, setEndTime] = useState(getEndTimeValueOrDefault());
  const { t } = useTranslation();

  return (
    <div className={`themeSwitcherWrapper themeSwitcherWrapper-${theme}`}>
      <h2>{t("filters.theme.title")}</h2>
      <MobilitiButton
        active={automaticDarkModeEnabled && !timedDarkMode.enabled}
        label={t("filters.theme.automatic")}
        onClick={toggleAutoDarkMode}
      />
      <MobilitiButton
        active={theme === "light" && !timedDarkMode.enabled && !automaticDarkModeEnabled}
        label={t("filters.theme.light")}
        onClick={() => changeTheme("light")}
      />
      <MobilitiButton
        active={theme === "dark" && !timedDarkMode.enabled && !automaticDarkModeEnabled}
        label={t("filters.theme.dark")}
        onClick={() => changeTheme("dark")}
      />
      <MobilitiButton
        active={timedDarkMode.enabled && !automaticDarkModeEnabled}
        label={t("filters.theme.timeBased")}
        onClick={toggleTimeBasedThemeSettings}
      />
      {timedDarkMode.enabled && (
        <div className="timeBasedThemeToggler">
          <input type="time" value={startTime} onChange={(event) => updateTimeBasedThemeSettings(event, "start")} />
          -
          <input type="time" value={endTime} onChange={(event) => updateTimeBasedThemeSettings(event, "end")} />
        </div>
      )}
    </div>
  );
}

ThemeSwitcher.propTypes = {};

export default ThemeSwitcher;
