import "rc-slider/assets/index.css";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { filterActions } from "../../../reducers/filtersReducer";
import { FilterType, RoamingFilterType } from "../../shared/Constants";
import "./FilterTypes.scss";
import { filterValues } from "./FiltersModal";
import RoamingToggle from "./RoamingToggle";

export const getFilterTypeStyle = (criteria, theme) => ({
  background: criteria ? (theme === "light" ? "white" : "#373737") : theme === "light" ? "" : "",
  boxShadow: criteria && theme === "light" ? "0px 4px 15px rgba(107, 110, 112, 0.15)" : "",
  border: criteria
    ? theme === "light"
      ? "1px solid rgba(0, 0, 0, 0)"
      : "1px solid rgba(0, 0, 0, 0)"
    : theme === "light"
    ? "1px solid #ECF2F8"
    : "1px solid rgb(56 56 56)",
});

function FilterTypes() {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { filterTypes, roamingFilterTypes } = useSelector((state) => state.filters);

  const updateFilterTypes = (filterType) => {
    dispatch(
      filterActions.updateFilters({
        filterTypes: filterValues(filterTypes, filterType),
      })
    );
  };

  const updateRoamingFilterTypes = (filterType) => {
    dispatch(
      filterActions.updateRoamingFilters({
        roamingFilterTypes: filterValues(roamingFilterTypes, filterType),
      })
    );
  };

  return (
    <>
      <div className="filterTypeWrapper">
        <h2>{t("filters.display")}</h2>

        {Object.values(FilterType).map(({ key, icon, iconDisabled, label }) => (
          <div
            className="filterType"
            key={key}
            style={getFilterTypeStyle(filterTypes.includes(key), themeContext.name)}
            onClick={() => updateFilterTypes(key)}
          >
            <img alt="filter type" src={filterTypes.includes(key) ? icon.default : iconDisabled.default} />
            <div className={`label ${!filterTypes.includes(key) ? "disabled" : ""}`}>{t(label)}</div>
          </div>
        ))}
      </div>
      <div className="filterTypeWrapper">
        <h2>{t("filters.roaming")}</h2>
        <span className="sectionHint">{t("filters.roamingHint")}</span>

        {Object.values(RoamingFilterType).map(({ key, icon, iconDisabled, label }) => (
          <div
            className="filterType"
            key={key}
            style={getFilterTypeStyle(roamingFilterTypes.includes(key), themeContext.name)}
            onClick={() => updateRoamingFilterTypes(key)}
          >
            <img alt="filter type" src={roamingFilterTypes.includes(key) ? icon.default : iconDisabled.default} />
            <div className={`label ${!roamingFilterTypes.includes(key) ? "disabled" : ""}`}>{t(label)}</div>
          </div>
        ))}
        <RoamingToggle />
      </div>
    </>
  );
}

export default FilterTypes;
