import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import footerIcon from "../../../assets/img/footer_icon.svg";
import star from "../../../assets/img/star.svg";
import starHalved from "../../../assets/img/star_half.svg";
import "./FooterTitles.scss";

function FooterTitles() {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <img
        alt="footer mobiliti icon"
        src={footerIcon}
        style={{ margin: "10px 10px 0px 10px" }}
        height="32"
        className="d-inline-block align-top"
      />
      <div className="mobilitiFooterTitle">
        <span
          style={{
            color: themeContext.name === "light" ? "" : "white",
          }}
        >
          Mobiliti
        </span>
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={starHalved} alt="star-halved" />
      </div>
      <div className="mobilitiFooterSubtitle">{t("footer.title")}</div>
    </>
  );
}

export default FooterTitles;
