import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import MobilitiButton from "../shared/elements/MobilitiButton";
import "./GenericModalFooter.scss";

function GenericModalFooter({ handleOk, children }) {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      className="modalFooter"
      style={{
        backgroundColor: themeContext.name === "light" ? "white" : "#1C1C1E",
        color: themeContext.name === "light" ? "" : "white",
      }}
    >
      {children && children}
      {!children && <MobilitiButton type="generic" label="OK" onClick={handleOk} />}
    </div>
  );
}

GenericModalFooter.propTypes = {
  handleOk: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default GenericModalFooter;
