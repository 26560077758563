import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { ThemeContext } from "styled-components";
import { comment, commentDark } from "../../../assets/img";
import api from "../../../config/api";
import { downloadMobilitiAppDialogOptions } from "../../shared/Constants";
import "./Comments.scss";

function Comments({ location }) {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (location) {
      api
        .fetchLocationComments({ locationId: location.id })
        .then(({ data }) => {
          setComments(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [location]);

  return (
    <div
      className={`comments ${themeContext.name}`}
      onClick={() => toastr.confirm(null, downloadMobilitiAppDialogOptions(themeContext.name, t("popup.downloadApp")))}>
      <img src={themeContext.name === "light" ? comment.default : commentDark.default} alt="comments" />
      <div className="comments-counter">{comments && comments.length ? comments.length : 0}</div>
    </div>
  );
}

Comments.propTypes = {
  location: PropTypes.object,
};

export default Comments;
