import { Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import api from "../../config/api";
import { setCurrentLocation } from "../../reducers/locationsReducer";
import { getZIndexForPin } from "./MapIconHelper";

function MobilitiMarker({ location, icon, clusterer }) {
  const dispatch = useDispatch();

  const fetchRoamingLocationDetails = useCallback(
    ({ countryCode, partyId, locationId }) => {
      api
        .fetchRoamingLocationDetails({ countryCode, partyId, locationId })
        .then(({ data }) => {
          dispatch(setCurrentLocation(convertRoamingLocation(data)));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [dispatch]
  );

  const convertRoamingLocation = (roamingLocation) => ({
    latitude: parseFloat(roamingLocation.latitude),
    longitude: parseFloat(roamingLocation.longitude),
    address: roamingLocation.sa,
    postalCode: roamingLocation.zip,
    roaming: true,
    name: roamingLocation.name,
    city: roamingLocation.city,
    ocpi: roamingLocation.ocpi,
    evses: roamingLocation.chargers.map((charger) => ({
      plugType: charger.plugType.toLowerCase(),
      label: charger.label,
      power: charger.power,
      amperage: charger.current,
      currentType: charger.currentType,
      voltage: charger.voltage,
      cableAttached: charger.cableAttached,
      evseId: charger.evseIdOcpi,
    })),
  });

  const showLocationDetails = useCallback(() => {
    if (location.roaming) {
      fetchRoamingLocationDetails(location);
    } else {
      dispatch(setCurrentLocation(location));
    }
  }, [dispatch, fetchRoamingLocationDetails, location]);

  return useMemo(() => {
    return (
      <Marker
        icon={{
          url: icon,
          scaledSize: new window.google.maps.Size(30, 37.5),
        }}
        noClustererRedraw={true}
        clusterer={clusterer}
        visible={!location.hidden}
        zIndex={getZIndexForPin(location)}
        position={{
          lat: Number(location.latitude),
          lng: Number(location.longitude),
        }}
        onClick={showLocationDetails}></Marker>
    );
  }, [location, icon, clusterer, showLocationDetails]);
}

export default MobilitiMarker;

MobilitiMarker.propTypes = {
  location: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  clusterer: PropTypes.object,
};
