import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "../../../config/api";
import "./Disclaimer.scss";

function Disclaimer() {
  const [legalDocuments, setLegalDocuments] = useState([]);
  const { language } = useSelector((state) => state.filters);
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    api
      .fetchLegalDocuments()
      .then(({ data }) => {
        const result = data?.documents ?? [];
        setLegalDocuments(result);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="mobilitiFooterDisclaimer">
      <a href="mailto:help@mobiliti.hu?Subject=MVM Mobiliti - toltopont.eu észrevétel">{t("footer.contact")} | </a>
      {legalDocuments.map((legalDocument) => (
        <a key={legalDocument.url} href={legalDocument.url}>
          {legalDocument.title[language]} |{" "}
        </a>
      ))}
      <span>© {currentYear} TöltőPont</span>
    </div>
  );
}

export default Disclaimer;
