import {
  acChargers,
  acChargersDisabled,
  ccs,
  ccsDark,
  ccsDarkDisabled,
  ccsDisabled,
  ccsSmall,
  ccsSmallDark,
  chademo,
  chademoDark,
  chademoDarkDisabled,
  chademoDisabled,
  chademoSmall,
  chademoSmallDark,
  dcChargers,
  dcChargersDisabled,
  ionity,
  mobilitiCharger,
  mobilitiChargerDisabled,
  mobilitiDc22,
  mobilitiDc50,
  mobilitiDc70,
  mobilitiSima,
  normalAcMax22kw,
  privat,
  publicCharger,
  publicChargerDisabled,
  roamingAc,
  roamingDc,
  shell,
  spar,
  tesla,
  teslaAc,
  teslaDark,
  teslaDarkDisabled,
  teslaDc,
  teslaDisabled,
  teslaSmall,
  teslaSmallDark,
  type2,
  type2Dark,
  type2DarkDisabled,
  type2Disabled,
  type2Small,
  type2SmallDark,
  villamAcDc,
  withCable,
  withCableDisabled,
} from "../../assets/img";
import StoreBadges from "../layout/Footer/StoreBadges";

export const ChargerTypeKeys = Object.freeze({
  CHADEMO: "chademo",
  CCS: "ccs",
  TESLA: "tesla",
  TYPE2: "type2",
});

export const ChargerType = Object.freeze({
  [ChargerTypeKeys.CHADEMO]: {
    key: ChargerTypeKeys.CHADEMO,
    light: {
      icon: chademo,
      iconDisabled: chademoDisabled,
      smallIcon: chademoSmall,
    },
    dark: {
      icon: chademoDark,
      iconDisabled: chademoDarkDisabled,
      smallIcon: chademoSmallDark,
    },
    label: "CHAdeMO",
  },
  [ChargerTypeKeys.CCS]: {
    key: ChargerTypeKeys.CCS,
    light: {
      icon: ccs,
      iconDisabled: ccsDisabled,
      smallIcon: ccsSmall,
    },
    dark: {
      icon: ccsDark,
      iconDisabled: ccsDarkDisabled,
      smallIcon: ccsSmallDark,
    },
    label: "CCS",
  },
  [ChargerTypeKeys.TESLA]: {
    key: ChargerTypeKeys.TESLA,
    light: {
      icon: tesla,
      iconDisabled: teslaDisabled,
      smallIcon: teslaSmall,
    },
    dark: {
      icon: teslaDark,
      iconDisabled: teslaDarkDisabled,
      smallIcon: teslaSmallDark,
    },
    label: "Tesla",
  },
  [ChargerTypeKeys.TYPE2]: {
    key: ChargerTypeKeys.TYPE2,
    light: {
      icon: type2,
      iconDisabled: type2Disabled,
      smallIcon: type2Small,
    },
    dark: {
      icon: type2Dark,
      iconDisabled: type2DarkDisabled,
      smallIcon: type2SmallDark,
    },
    label: "Type 2",
  },
});

export const FilterTypeKeys = Object.freeze({
  MOBILITI: "mobiliti",
  WITH_CABLE: "withCable",
  AC_CHARGER: "acCharger",
  DC_CHARGER: "dcCharger",
  PUBLIC_ONLY: "publicOnly",
});

export const FilterType = Object.freeze({
  [FilterTypeKeys.MOBILITI]: {
    key: FilterTypeKeys.MOBILITI,
    label: "filters.types.mobiliti",
    icon: mobilitiCharger,
    iconDisabled: mobilitiChargerDisabled,
  },
  [FilterTypeKeys.WITH_CABLE]: {
    key: FilterTypeKeys.WITH_CABLE,
    label: "filters.types.withCable",
    icon: withCable,
    iconDisabled: withCableDisabled,
  },
  [FilterTypeKeys.AC_CHARGER]: {
    key: FilterTypeKeys.AC_CHARGER,
    label: "filters.types.acCharger",
    icon: acChargers,
    iconDisabled: acChargersDisabled,
  },
  [FilterTypeKeys.DC_CHARGER]: {
    key: FilterTypeKeys.DC_CHARGER,
    label: "filters.types.dcCharger",
    icon: dcChargers,
    iconDisabled: dcChargersDisabled,
  },
  [FilterTypeKeys.PUBLIC_ONLY]: {
    key: FilterTypeKeys.PUBLIC_ONLY,
    label: "filters.types.publicOnly",
    icon: publicCharger,
    iconDisabled: publicChargerDisabled,
  },
});

export const RoamingFilterTypeKeys = Object.freeze({
  AC_CHARGER: "acCharger",
  DC_CHARGER: "dcCharger",
});

export const RoamingFilterType = Object.freeze({
  [RoamingFilterTypeKeys.AC_CHARGER]: {
    key: RoamingFilterTypeKeys.AC_CHARGER,
    label: "filters.types.roamingAcCharger",
    icon: acChargers,
    iconDisabled: acChargersDisabled,
  },
  [RoamingFilterTypeKeys.DC_CHARGER]: {
    key: RoamingFilterTypeKeys.DC_CHARGER,
    label: "filters.types.roamingDcCharger",
    icon: dcChargers,
    iconDisabled: dcChargersDisabled,
  },
});

export const LegendItems = [
  {
    icon: mobilitiDc70,
    header: "legend.mobilitiDc70.title",
    subHeader: "legend.mobilitiDc70.subtitle",
  },
  {
    icon: mobilitiDc50,
    header: "legend.mobilitiDc50.title",
    subHeader: "legend.mobilitiDc50.subtitle",
  },
  {
    icon: mobilitiDc22,
    header: "legend.mobilitiDc22.title",
    subHeader: "legend.mobilitiDc22.subtitle",
  },
  {
    icon: mobilitiSima,
    header: "legend.mobilitiAc.title",
    subHeader: "legend.mobilitiAc.subtitle",
  },
  {
    icon: spar,
    header: "legend.spar.title",
    subHeader: "legend.spar.subtitle",
  },
  {
    icon: shell,
    header: "legend.shell.title",
    subHeader: "legend.shell.subtitle",
  },
  {
    icon: ionity,
    header: "legend.ionity.title",
    subHeader: "legend.ionity.subtitle",
  },
  {
    icon: villamAcDc,
    header: "legend.acAndDcRapid.title",
    subHeader: "legend.acAndDcRapid.subtitle",
  },
  {
    icon: normalAcMax22kw,
    header: "legend.normalAcCharger.title",
    subHeader: "legend.normalAcCharger.subtitle",
  },
  {
    icon: privat,
    header: "legend.private.title",
    subHeader: "legend.private.subtitle",
  },
  {
    icon: teslaDc,
    header: "legend.teslaDcSupercharger.title",
    subHeader: "legend.teslaDcSupercharger.subtitle",
  },
  {
    icon: teslaAc,
    header: "legend.teslaDestinationCharger.title",
    subHeader: "legend.teslaDestinationCharger.subtitle",
  },
  {
    icon: roamingDc,
    header: "legend.roamingAc.title",
    subHeader: "legend.roamingAc.subtitle",
  },
  {
    icon: roamingAc,
    header: "legend.roamingDc.title",
    subHeader: "legend.roamingDc.subtitle",
  },
];

export const Language = Object.freeze({
  HU: "hu",
  EN: "en",
});

export const clustererOptions = Object.freeze({
  minimumClusterSize: 5,
  gridSize: 100,
  zoomOnClick: false,
  ignoreHidden: true,
});

export const downloadMobilitiAppDialogOptions = (theme, label) => ({
  disableCancel: true,
  okText: "OK",
  id: `mobiliti-popup-${theme}`,
  component: () => (
    <div
      style={{
        padding: "10px",
        fontWeight: 500,
        textAlign: "center",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        {label}
      </div>
      <div>
        <StoreBadges />
      </div>
    </div>
  ),
});
