import { useSelector } from "react-redux";
import "./Snowflakes.scss";

function Snowflakes() {
  const { isSnowing } = useSelector((state) => state.theme);

  if (!isSnowing) {
    return null;
  }

  return (
    <div className="snowflakes" aria-hidden="true">
      {Array(100)
        .fill(null)
        .map((item, index) => (
          <div key={index} className="snowflake">
            ❅
          </div>
        ))}
    </div>
  );
}

export default Snowflakes;
