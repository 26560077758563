import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import "./GenericModal.scss";
import GenericModalFooter from "./GenericModalFooter";
import GenericModalHeader from "./GenericModalHeader";

function GenericModal({ show, onHide, content, footer }) {
  const themeContext = useContext(ThemeContext);

  const handleOk = (e) => {
    e.stopPropagation();
    onHide();
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <>
      <div
        className="mobilitiModal"
        style={{
          transform: show ? "translateY(0)" : "translateY(-100vh)",
          opacity: show ? 1 : 0,
          backgroundColor: themeContext.name === "light" ? "white" : "black",
          color: themeContext.name === "light" ? "" : "white",
        }}
      >
        <GenericModalHeader handleCancel={handleCancel} />
        <div className="mobilitiModalContent">{content}</div>
        {!!footer && <GenericModalFooter handleOk={handleOk}>{footer}</GenericModalFooter>}
      </div>
    </>
  );
}

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showFooter: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  content: PropTypes.any.isRequired,
  footer: PropTypes.any,
};

export default GenericModal;
