import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import { LegendItems } from "../../shared/Constants";
import { Hr } from "../../shared/elements/Hr";
import GenericModal from "../GenericModal";
import LegendItem from "./LegendItem";
import "./LegendModal.scss";

function LegendModal({ show, onHide }) {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const modalContent = (
    <>
      <h1>{t("legend.title")}</h1>
      {LegendItems.map(({ icon, header, subHeader }, index) => (
        <div key={header}>
          <LegendItem subHeader={subHeader} icon={icon.default} header={header} />
          {index < LegendItems.length - 1 ? <Hr themeContext={themeContext} /> : ""}
        </div>
      ))}
    </>
  );

  return (
    <>
      <GenericModal show={show} content={modalContent} onHide={onHide}></GenericModal>
    </>
  );
}

LegendModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default LegendModal;
