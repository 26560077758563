import { useSelector } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { ThemeProvider } from "styled-components";
import "../../App.scss";
import { useAutoDarkMode } from "../../hooks/useAutoDarkMode";
import useLocationFilters from "../../hooks/useLocationFilters";
import { useTimedDarkMode } from "../../hooks/useTimedDarkMode";
import { darkTheme, lightTheme } from "../../theme";
import Snowflakes from "../shared/Snowflakes";
import Content from "./Content";
import Footer from "./Footer/Footer";
import Header from "./Header";

function AppContainer() {
  const { name: theme } = useSelector((state) => state.theme);
  useTimedDarkMode();
  useAutoDarkMode();
  useLocationFilters();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={false}
        position="top-left"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
      <Snowflakes />
      <Header />
      <Content />
      <Footer />
    </ThemeProvider>
  );
}

export default AppContainer;
